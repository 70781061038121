import React, { useState } from 'react';
import { Row, Form, Col, Dropdown } from 'react-bootstrap';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
    className="ml-3 mr-3 mt-2 d-flex justify-content-between"
  >
    {children}
  </div>
));

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={e => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            child =>
              !value ||
              child.props.children.toLowerCase().startsWith(value.toLowerCase())
          )}
        </ul>
      </div>
    );
  }
);

function SelectWithSearch({
  input,
  label,
  meta: { touched, error },
  options,
  initialValue,
}) {
  const [toggleLabel, setToggleLabel] = useState(initialValue);

  const onSelect = eventKey => {
    setToggleLabel(eventKey);
    input.onChange(eventKey);
  };

  return (
    <Form.Group as={Row}>
      {label && <Col sm={3}>{label}</Col>}
      <Col sm={9}>
        <Dropdown
          onSelect={onSelect}
          className="square border border-1 rounded"
          style={{ height: '40px' }}
        >
          <Dropdown.Toggle as={CustomToggle}>
            <div>{toggleLabel}</div>
            <div> &#x25bc;</div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="scrollable" as={CustomMenu}>
            {options.map(({ name, value, key }) => (
              <Dropdown.Item
                key={key}
                eventKey={value}
                className="dropdown-item"
              >
                {name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        {touched && error && <span className="text-danger">{error}</span>}
      </Col>
    </Form.Group>
  );
}

export default SelectWithSearch;
