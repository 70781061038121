import React from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './scss/app.scss';
import './fonts/poppins/stylesheet.css';
import './fonts/montserrat/stylesheet.css';

import reducers from './redux/reducers';

import LocationList from './containers/Companies/components/CompanyList/CompanyList';
import LocationSettings from './containers/LocationSettings/LocationSettings';
import Login from './containers/Account/Login/Login';
import Logout from './containers/Account/Logout/Logout';
import Furniture from './containers/Furniture/Furniture';
import MagicAuth from './containers/MagicAuth/MagicAuth';
import PageNotFound from './containers/404/PageNotFound';
import Hotspot from './containers/Hotspot/Hotspot';
import Icons from './containers/Icons/Icons';
import Explorer3dList from 'containers/Explorer3d/Explorer3dList';
import Explorer3dWrapper from 'containers/Explorer3d/Explorer3dWrapper';

const composeStoreWithMiddleware = applyMiddleware(thunk)(createStore);

const store = composeStoreWithMiddleware(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
window.store = store;

export default function App() {
  return (
    <Provider store={store}>
      <Router>
        <MagicAuth>
          <Switch>
            <Route path="/dashboard" component={LocationList} />
            <Route exact path="/furniture" component={Furniture} />
            <Route exact path="/hotspots" component={Hotspot} />
            <Route exact path="/icons" component={Icons} />
            <Route exact path="/widgets" component={Explorer3dList} />
            <Route exact path="/widgets/:id" component={Explorer3dWrapper} />
            {/* <Route exact path="/hotspot" component={Hotspot} /> */}
            {/* <Route exact path="/hotspot/:locationId" component={LocationHotspot} /> */}
            <Route path="/location/:id" component={LocationSettings} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/logout" component={Logout} />
            <Route path="/404" component={PageNotFound} />
            <Route path="" component={LocationList} />
          </Switch>
        </MagicAuth>
      </Router>
    </Provider>
  );
}
