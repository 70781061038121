import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

const baseStyle = {};

const acceptStyle = {
  backgroundColor: '#D9EAFF',
  borderColor: '#007BFF',
};

function Dropzone({
  onFilesSelected,
  primaryText,
  secondaryText,
  buttonText,
  style = {},
}) {
  const onDrop = useCallback(
    files => {
      onFilesSelected(files);
    },
    [onFilesSelected]
  );

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDrop,
  });

  const innerStyle = useMemo(
    () => ({
      ...baseStyle,
      ...style,
      ...(isDragAccept ? acceptStyle : {}),
    }),
    [isDragAccept, style]
  );

  return (
    <div className="dropzone" {...getRootProps({ style: innerStyle })}>
      <img
        src={`${process.env.PUBLIC_URL}/img/upload-file.svg`}
        className="icon"
      />
      <p className="main-text">{primaryText}</p>
      <input {...getInputProps()} />
      <p className="secondary-text">{secondaryText}</p>
      <button className="select-files">{buttonText}</button>
    </div>
  );
}

export default Dropzone;
