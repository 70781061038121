import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { reduxForm } from 'redux-form';

const AwsPopup = ({ show, onClose, item }) => {
  const close = () => {
    onClose && onClose();
  };

  const fileFormat = item?.fileName?.split('.').at(-1);

  const isImage =
    fileFormat === 'jpg' ||
    fileFormat === 'img' ||
    fileFormat === 'webp' ||
    fileFormat === 'jpeg' ||
    fileFormat === 'png';

  return (
    <div>
      <Modal
        show={show}
        onHide={close}
        style={{
          height: '100vh',
          justifyContent: 'center',
          marginTop: 0,
          alignItems: 'center',
          marginBottom: 0,
          display: 'flex !important',
        }}
      >
        <button onClick={close} className="close-button">
          <img src={`${process.env.PUBLIC_URL}/img/popup-close.svg`} />
        </button>
        {isImage ? (
          <img
            src={item?.link}
            style={{  maxWidth: '100%', backgroundSize: 'cover' }}
          />
        ) : (
          <video controls style={{ height: '100%', width: '100%' }}>
            <source src={item?.link} type="video/webm" />
          </video>
        )}
      </Modal>
      <style jsx>{`
        .modal-dialog {
          height: 50%;
          width: 50%;
          max-width: 2000px;
        }
        .modal-open .modal {
          display: flex !important;
        }
        .close-button {
          position: absolute;
          right: 12px;
          top: 12px;
          height: 24px;
          width: 24px;
          background: white;
          border: none;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
        }
        .close-button:hover {
          background: #cccccc;
        }
        .modal-content {
          height: 100%;
        }
        .modal-content {
          background-size: cover;
        }
      `}</style>
    </div>
  );
};

AwsPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'popup_form',
})(AwsPopup);
