import React, { useState, useEffect, useRef } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import IslandContainer from '../IslandContainer';
import renderField from '../../../shared/components/form/Field';
import renderSelectWithSearch from '../../../shared/components/form/SelectWithSearch';
import {
  loadUnits,
  loadUnitTypes,
  loadAwsUrls,
  deleteFileFromAws,
  uploadFilesToAws,
  createToast,
} from 'redux/actions';
import ContentFileInput from '../ContentFileInput';

import AwsUrlsContainer from './AwsUrlsContainer';

const sightmapBasePath = process.env.REACT_APP_SIGHTMAP_URL;

const explorer3dBasePath = process.env.REACT_APP_EXPLORER3D_URL;

function ContentEditor({ isFullScreen }) {
  const dispatch = useDispatch();
  const explorer3d = useSelector(state => state.explorer3d.current);

  const floorplanInternalLinkRef = useRef(null);
  const floorplanInternal360LinkRef = useRef(null);
  const unitInternalLinkRef = useRef(null);

  const handleClick = async (url, filename) => {
    const image = await fetch(`${sightmapBasePath}/aws/download`, {
      method: 'POST',
      body: JSON.stringify({ url }),
      headers: { 'content-type': 'application/json' },
    });
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const link = document.createElement('a');
    link.href = imageURL;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  useEffect(() => {
    if (explorer3d?.id) {
      dispatch(loadUnits(explorer3d.id));
      dispatch(loadUnitTypes(explorer3d.id));
    }
  }, [dispatch, explorer3d?.id]);
  const [currentFloorType, setCurrentFloorType] = useState(null);
  const [currentUnit, setCurrentUnit] = useState(null);
  const [currentEntityType, setCurrentEntityType] = useState('floorplans');
  const [currentTourType, setCurrentTourType] = useState('internalTour');
  const [currentContentType, setCurrentContentType] = useState(null);

  const unittypes =
    useSelector(state => state.explorer3d.current.unittypes) || [];

  const units = useSelector(state => state.explorer3d.current.units) || [];

  useEffect(() => {
    if (currentEntityType === 'floorplans') {
      setCurrentContentType('plans');
    } else {
      setCurrentContentType('images');
    }
  }, [currentEntityType]);

  useEffect(() => {
    const current =
      currentEntityType === 'floorplans' ? currentFloorType : currentUnit;

    if (current && currentContentType && currentEntityType) {
      dispatch(
        loadAwsUrls(
          explorer3d.id,
          current,
          currentContentType,
          currentEntityType
        )
      );
    }
  }, [
    currentFloorType,
    currentContentType,
    currentEntityType,
    currentUnit,
    dispatch,
    explorer3d.id,
  ]);

  const handleDelete = url => {
    const current =
      currentEntityType === 'floorplans' ? currentFloorType : currentUnit;
    const locationId = explorer3d.id;
    const entityId = current?.id;
    const entityType = currentEntityType;
    const contentType = currentContentType;
    dispatch(
      deleteFileFromAws(url, locationId, entityId, contentType, entityType)
    );
  };

  function getApartmentByName(unitTypes, name) {
    return unitTypes.find(type => type.name === name);
  }

  function getUnitById(units, number) {
    return units.find(type => type.internalNumber === Number(number));
  }

  async function uploadFilesHandler(files) {
    const current =
      currentEntityType === 'floorplans' ? currentFloorType : currentUnit;

    const bucketName = 'seenic-dev-content';

    const folderName = `locations/${explorer3d.id}/${currentEntityType}/${current?.id}/content/${currentContentType}`;
    if (!Array.from(files).length) {
      dispatch(createToast('ERROR', 'Files are not attached'));

      return;
    }
    const modifiedFiles = Array.from(files).map(file => {
      const newFileName = file.name.replace(/\s+/g, '_'); // Replace spaces with underscores

      return new File([file], newFileName, { type: file.type });
    });

    console.log(modifiedFiles);

    try {
      await dispatch(uploadFilesToAws(modifiedFiles, bucketName, folderName));
    } catch (error) {
      dispatch(createToast('ERROR', 'Unknown error'));
    }
  }

  const tourTypes = [{ name: 'internalTour' }, { name: 'withoutTour' }];
  const floorplanContentTypes = [
    { name: 'plans' },
    { name: 'images' },
    { name: 'videos' },
  ];

  const unitContentTypes = [
    { name: 'images' },
    { name: 'balconies' },
    { name: 'videos' },
  ];

  const contentType =
    currentEntityType === 'floorplans'
      ? floorplanContentTypes
      : unitContentTypes;

  const updateUnitTourLink = async () => {
    const current =
      currentEntityType === 'floorplans' ? currentFloorType : currentUnit;
    await fetch(`${explorer3dBasePath}/units/${current?.id}/update-tour`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        link: unitInternalLinkRef?.current?.value,
      }),
    });
  };

  const updateFloorplanTourLink = async () => {
    const current =
      currentEntityType === 'floorplans' ? currentFloorType : currentUnit;
    await fetch(`${explorer3dBasePath}/unit-types/${current?.id}/update-tour`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        link: floorplanInternalLinkRef?.current?.value,
      }),
    });
  };

  const updateFloorplan360Link = async () => {
    const current =
      currentEntityType === 'floorplans' ? currentFloorType : currentUnit;
    await fetch(
      `${explorer3dBasePath}/unit-types/${current?.id}/update-spinner`,
      {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          link: floorplanInternal360LinkRef?.current?.value,
        }),
      }
    );
  };

  return (
    <>
      <Container className="no-padding" fluid={isFullScreen}>
        <IslandContainer isFullScreen={isFullScreen} className="drop-container">
          <h2 className="content-label">Content Editor</h2>
          <Container className="content-editor-item">
            <h2 className="content-description">Entity type</h2>
            <Container className="gap-1 flex-column d-flex">
              <Form.Check
                className="d-flex flex-row align-items-center form-check-label"
                type="radio"
                id="floorplans"
                label="Floorplans"
                name="floorplans"
                value="text"
                checked={currentEntityType === 'floorplans'}
                onChange={() => {
                  setCurrentEntityType('floorplans');
                }}
              />
              <Form.Check
                className="d-flex flex-row align-items-center form-check-label"
                type="radio"
                id="Units"
                label="Units"
                name="Units"
                value="textarea"
                checked={currentEntityType === 'units'}
                onChange={() => {
                  setCurrentEntityType('units');
                }}
              />
            </Container>
          </Container>
          {currentEntityType && currentEntityType === 'floorplans' && (
            <Field
              name="Entity"
              label="Floorplan type"
              options={unittypes.map((entity, index) => ({
                name: entity.name,
                value: entity.name,
                key: `${index}c`,
              }))}
              component={renderSelectWithSearch}
              initialValue={currentFloorType?.name ?? ''}
              onChange={e => {
                setCurrentFloorType(getApartmentByName(unittypes, e));
              }}
            />
          )}
          {currentEntityType && currentEntityType === 'units' && (
            <Field
              name="Entity"
              label="Unit type"
              options={units.map((entity, index) => ({
                name: '' + entity.internalNumber,
                value: '' + entity.internalNumber,
                key: `${index}c`,
              }))}
              component={renderSelectWithSearch}
              initialValue={currentUnit?.internalNumber ?? ''}
              onChange={e => setCurrentUnit(getUnitById(units, e))}
            />
          )}
          {(currentFloorType || currentUnit) && (
            <Field
              name="Content Type"
              label="Content type"
              options={contentType.map((entity, index) => ({
                name: entity.name,
                value: entity.name,
                key: `${index}c`,
              }))}
              component={renderSelectWithSearch}
              onChange={e => {
                setCurrentContentType(e);
              }}
              initialValue={currentContentType}
            />
          )}
          {currentEntityType === 'floorplans' &&
            currentFloorType &&
            currentContentType &&
            currentTourType === tourTypes[0].name && (
              <>
                <Field
                  name="floorplans_spinner_link"
                  type="text"
                  label="360 Spinner link"
                  component={renderField}
                  ref={floorplanInternal360LinkRef}
                  initialValue={
                    getApartmentByName(unittypes, currentFloorType?.name)
                      ?.spinnerLink
                  }
                />
                <Button
                  className="btn-primary"
                  onClick={updateFloorplan360Link}
                >
                  Update 360 Floorplan Link
                </Button>
              </>
            )}
          {currentEntityType &&
            (currentFloorType || currentUnit) &&
            currentContentType && (
              <Container className="content-editor-item">
                <h2 className="content-description">Tour type/link</h2>
                <Container className="gap-1 flex-column d-flex">
                  <Form.Check
                    className="d-flex flex-row align-items-center form-check-label"
                    type="radio"
                    id="without-tour-type"
                    label="Without a tour"
                    name="without-tour-type"
                    value="withoutTour"
                    checked={currentTourType === tourTypes[1].name}
                    onChange={() => {
                      setCurrentTourType(tourTypes[1].name);
                    }}
                  />
                  <Form.Check
                    className="d-flex flex-row align-items-center form-check-label"
                    type="radio"
                    id="internal-tour-type"
                    label="Internal tour"
                    name="internal-tour-type"
                    value="internalTour"
                    checked={currentTourType === tourTypes[0].name}
                    onChange={() => {
                      setCurrentTourType(tourTypes[0].name);
                    }}
                  />
                </Container>
              </Container>
            )}
          {currentEntityType === 'floorplans' &&
            currentFloorType &&
            currentContentType &&
            currentTourType === tourTypes[0].name && (
              <>
                <Field
                  name="floorplans_internal_tour_link"
                  type="text"
                  label="Internal tour link"
                  component={renderField}
                  ref={floorplanInternalLinkRef}
                  initialValue={
                    getApartmentByName(unittypes, currentFloorType?.name)
                      ?.tourLink
                  }
                />
                <Button
                  className="btn-primary"
                  onClick={updateFloorplanTourLink}
                >
                  Update Tour Link
                </Button>
              </>
            )}
          {currentEntityType === 'units' &&
            currentUnit &&
            currentContentType &&
            currentTourType === tourTypes[0].name && (
              <>
                <Field
                  name="unit_internal_tour_link"
                  type="text"
                  label="Internal tour link"
                  component={renderField}
                  ref={unitInternalLinkRef}
                  initialValue={
                    getUnitById(units, currentUnit?.internalNumber)?.tourLink
                  }
                />
                <Button className="btn-primary" onClick={updateUnitTourLink}>
                  Update Tour Link
                </Button>
              </>
            )}
        </IslandContainer>
      </Container>
      <Container className="no-padding" fluid={isFullScreen}>
        {currentEntityType &&
          ((currentEntityType === 'floorplans' && currentFloorType) ||
            (currentEntityType === 'units' && currentUnit)) &&
          currentContentType && (
            <IslandContainer
              isFullScreen={isFullScreen}
              className="drop-container"
            >
              <h2 className="content-label">Content / Gallery</h2>
              <ContentFileInput
                buttonLabel="Upload Content"
                isMultiple={true}
                onSubmit={uploadFilesHandler}
              />
            </IslandContainer>
          )}
        {currentEntityType &&
          ((currentEntityType === 'floorplans' && currentFloorType) ||
            (currentEntityType === 'units' && currentUnit)) &&
          currentContentType && (
            <IslandContainer isFullScreen={isFullScreen}>
              <AwsUrlsContainer
                entityId={
                  currentEntityType === 'floorplans'
                    ? currentFloorType?.id
                    : currentUnit?.id
                }
                contentType={currentContentType}
                handleClick={handleClick}
                handleDelete={handleDelete}
                isUnit={currentEntityType === 'units'}
                uploadPreviewId={
                  currentEntityType === 'units'
                    ? currentUnit.id
                    : currentFloorType.id
                }
              />
            </IslandContainer>
          )}
      </Container>
      <style jsx>{`
        .image-grid {
          flex-wrap: wrap;
          gap: 16px;
          justify-content: flex-start;
        }

        .image-item {
          flex: 1 1 calc(33.333% - 16px);
          max-width: calc(33.333% - 16px);
          box-sizing: border-box;
          position: relative;
          height: 260px;
          position: relative;
        }
        .image-item a {
          height: 100%;
          position: relative;
        }
        .image-item img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .btn-danger {
          position: absolute;
          width: 48px;
          top: 12px;
          right: 12px;
          height: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .btn-download {
          position: absolute;
          width: 48px;
          top: 12px;
          right: 72px;
          height: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #28a745;
        }
        .btn-view {
          position: absolute;
          width: 48px;
          top: 12px;
          right: 132px;
          height: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #007bff;
        }
        .delete-alert {
          display: flex;
          position: absolute;
          top: 12px;
          right: 12px;
          padding: 8px 12px 8px 12px;
          background: #343a40cc;
          gap: 12px;
          border-radius: 4px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          color: white;
          align-items: center;
        }
        .delete-alert button {
          height: 34px;
          padding: 5px 12px 5px 12px;
          gap: 6px;
          border-radius: 4px;
          border: 1px solid #fafafa;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          background: transparent;
          color: white;
        }
        .delete-alert-danger-button {
          background: #dc3545 !important;
          border: none !important;
        }
        .drop-container {
          padding: 16px !important;
          background: red;
        }
        @media (max-width: 768px) {
          .image-item {
            flex: 1 1 calc(50% - 16px);
            max-width: calc(50% - 16px);
          }
        }
        @media (max-width: 480px) {
          .image-item {
            flex: 1 1 100%;
            max-width: 100%;
          }
        }
      `}</style>
    </>
  );
}

export default reduxForm({ form: 'contentEditorForm' })(ContentEditor);
