import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

function SwitchEditorTypeButton({ clickHandler, variant, tooltip, children }) {
  return (
    <OverlayTrigger
      placement="top"
      flip
      overlay={<Tooltip id="b-t">{tooltip}</Tooltip>}
    >
      <Button
        className="explorer3d-header-btn"
        variant={variant}
        onClick={clickHandler}
        size="sm"
      >
        {children}
      </Button>
    </OverlayTrigger>
  );
}

export default SwitchEditorTypeButton;
