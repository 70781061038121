import { produce } from 'immer';
import * as actionTypes from '../actions/types';

const initialState = {
  dataCSV: null,
  isDataCSVLoading: false,
  propertySVGCoordinates: null,
  floorSVGs: null,
};

const EditorCSVReducer = produce((state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DATA_CSV: {
      state.dataCSV = action.payload;
      break;
    }

    case actionTypes.SET_IS_DATA_CSV_LOADING: {
      state.isDataCSVLoading = action.isDataCSVLoading;
      break;
    }

    case actionTypes.SET_PROPERTY_SVG_COORDINATES: {
      state.propertySVGCoordinates = action.propertySVGCoordinates;
      break;
    }

    case actionTypes.SET_FLOOR_SVGS: {
      state.floorSVGs = action.floorSVGs;
      break;
    }

    default:
      return state;
  }
});

export default EditorCSVReducer;
