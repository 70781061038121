import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as zipjs from '@zip.js/zip.js';
import { Col, Row } from 'react-bootstrap';

import FileInput from '../../../shared/components/form/FileInput';
import { validateFramesZip } from '../../../shared/utils/validate';
import AlertTooltipIcon from '../../../shared/components/AlertTooltipIcon';
import InfoTooltipIcon from '../../../shared/components/InfoTooltipIcon';
import { createToast } from '../../../redux/actions';

const FrameZipInput = ({
  onSubmit,
  onFileSelect,
  allowUploading,
  uploading,
  framesUploaded,
  uploaded,
  error,
  dispatch,
  id,
}) => {
  const [equiFileNamesServerContains, setEquiFileNamesServerContains] =
    useState([]);

  const handleSubmit = async files => {
    try {
      const withoutSlicing = true;
      if (files) {
        const archiveExt = ['zip', '7z', 'rar'];
        const fileExt = files.name.split('.')[1];
        const isArchive = archiveExt.includes(fileExt);

        if (isArchive) {
          const reader = new zipjs.ZipReader(new zipjs.BlobReader(files));
          const zip = await reader.getEntries();

          for (const entry of zip) {
            const pathParts = entry.filename.split('/');
            const folderName = pathParts[pathParts.length - 2];
            if (
              folderName.toLowerCase() === 'high' ||
              folderName.toLowerCase() === 'low'
            ) {
              const newPath =
                pathParts.slice(0, pathParts.length - 2).join('/') +
                '/' +
                folderName.toLowerCase() +
                '/' +
                pathParts[pathParts.length - 1];
              entry.filename = newPath;
            }
          }

          validateFramesZip(zip, files.name);

          await new Promise(rs => setTimeout(rs, 3000));

          onSubmit({
            files,
            withoutSlicing,
          });
        }
      }
    } catch (e) {
      dispatch(createToast('ERROR', e.message, e.heading));
    }
  };

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/getFrameFileNamesFromLocation?locationId=${id}`
    )
      .then(r => r.json())
      .then(r => r.result)
      .then(setEquiFileNamesServerContains);
  }, [uploaded, framesUploaded]);

  const alert = !uploaded && (
    <AlertTooltipIcon tooltip="The frames has not been uploaded" />
  );

  const info = (
    <InfoTooltipIcon
      tooltip={
        'After selecting the file, wait for it to unpack, then click "Upload". Zip archive should include 2 folders inside: "high" with files "frame_1", "frame_2", "frame_3", etc and "low" with files "frame_low_1", "frame_low_2", "frame_low_3", etc.'
      }
    />
  );

  return (
    <>
      <Row>
        <Col>
          <FileInput
            label={
              <h5>
                Frames uploading <span className="text-muted">(.zip)</span>{' '}
                {info} {alert}
              </h5>
            }
            accept={'.zip'}
            onSubmit={async files => await handleSubmit(files)}
            onFileSelect={onFileSelect}
            submitButtonLabel={
              framesUploaded
                ? 'Frames uploaded'
                : uploading
                  ? 'Uploading...'
                  : 'Upload'
            }
            submitButtonDisabled={uploading || !allowUploading}
            errorText={error}
            disableInput={uploading}
          />
        </Col>
      </Row>
    </>
  );
};

FrameZipInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onFileSelect: PropTypes.func.isRequired,
  allowUploading: PropTypes.bool,
  uploading: PropTypes.bool,
  uploaded: PropTypes.bool,
  error: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.number,
};

FrameZipInput.defaultProps = {
  allowUploading: false,
  uploading: false,
  tilesUploaded: false,
  error: '',
};

export default connect(state => state)(FrameZipInput);
