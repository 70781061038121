export const TAB_ENUM = {
  SETTINGS: 'SETTINGS',
  FLOORS: 'FLOORS',
  AMENITIES: 'AMENITIES',
  CONTENT_EDITOR: 'CONTENT_EDITOR',
  MARKERS: 'MARKERS',
  CYCLES: 'CYCLES',
};

export const FLOOR_STATUS = {
  DOWNLOADED: 'DOWNLOADED',
  EMPTY: 'EMPTY',
};

export const MEDIA_URL = process.env.REACT_APP_EXPLORER3D_MEDIA_URL;
