import { Container } from 'react-bootstrap';
import React from 'react';

function IslandContainer({
  children,
  isFullScreen,
  color = 'primary',
  id,
  noPadding,
  className = '',
}) {
  return (
    <Container
      className={`rounded ${color === 'primary' ? 'bg-white' : 'bg-black'}  ${noPadding ? 'no-padding' : 'p-2'} ${className}`}
      fluid={isFullScreen}
      id={id}
    >
      {children}
    </Container>
  );
}

export default IslandContainer;
