import { useContext } from 'react';
import { ReactReduxContext } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  createToast,
  hidePopup,
  loadLocationsOfCompany,
  showPopup,
} from '../../../../redux/actions';
import API from '../../../../shared/utils/API';
import { locationNameRegex } from '../../../AddLocation/components/validate';

export const useDeleteLocation = ({
  locationId,
  locationName,
  companyName,
  goBack,
  onDelete,
}) => {
  const { store } = useContext(ReactReduxContext);
  const dispatch = store.dispatch;
  const history = useHistory();

  return () =>
    dispatch(
      showPopup({
        onSubmit: async () => {
          try {
            const data = {
              locationId,
              locationName,
              companyName,
            };

            API.request(
              API.endpoints.CANCEL_TILES,
              null,
              `?location=${locationId}`
            );

            await API.request(API.endpoints.DELETE_LOCATION, data);
            dispatch(
              createToast('SUCCESS', 'Location has been deleted successfully')
            );

            if (goBack) {
              history.push('/');
            }

            if (onDelete) {
              onDelete();
            }
          } catch (e) {
            dispatch(createToast('ERROR', e.toString()));
            console.error(e);
          } finally {
            dispatch(hidePopup());
          }
        },
        label: {
          text: 'Are you sure?',
          color: 'black',
        },
      })
    );
};

export const useRenameLocation = (
  locationId,
  description,
  companyName,
  name
) => {
  const { store } = useContext(ReactReduxContext);
  const dispatch = store.dispatch;

  return () =>
    dispatch(
      showPopup({
        onSubmit: async values => {
          try {
            const data = {
              locationId,
              name: values.name,
              description: `'${JSON.stringify({
                ...description,
                LOCATION_NAME: values.name,
              })}'`,
            };
            await API.request(API.endpoints.UPDATE_LOCATION_FULL, data);
            dispatch(
              createToast('SUCCESS', 'Location has been renamed successfully')
            );
            dispatch(loadLocationsOfCompany(companyName));
          } catch (e) {
            dispatch(createToast('ERROR', e.toString()));
            console.error(e);
          } finally {
            dispatch(hidePopup());
          }
        },
        label: {
          text: 'Please enter new name',
          color: 'black',
        },
        submitButton: {
          text: 'Rename',
          variant: 'success',
        },
        enableReinitialize: true,
        initialValues: {
          name: name || '',
        },
        validate: data => {
          const errors = {};
          if (locationNameRegex.test(data.name)) {
            errors.name = 'Field contains forbidden characters';
          }
          if (!data.name.trim()) {
            errors.name = 'Required field';
          }

          return errors;
        },
        formData: [
          {
            type: 'input',
            name: 'name',
            props: {
              placeholder: 'Location name',
              type: 'text',
              onChangeValidate: true,
            },
          },
        ],
      })
    );
};
