import React, { useState, useCallback, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Card } from 'react-bootstrap';
import ImagePlusIcon from 'mdi-react/ImagePlusIcon';

import { ReactReduxContext } from 'react-redux';
import { createToast } from '../../../redux/actions';

import getBase64 from '../../utils/getBase64';

// max size in mb
const DropZoneField = ({
  onChange,
  label,
  image,
  base64,
  accept,
  style,
  maxSize,
}) => {
  const [thumbnail, setThumbnail] = useState(image);
  const size = useMemo(() => (thumbnail ? 350 : 250), [thumbnail]);
  const { store } = useContext(ReactReduxContext);

  const onDrop = useCallback(async files => {
    const file = files[0];
    const mbSize = file.size / 1e6;

    if (maxSize && mbSize > maxSize) {
      store.dispatch(
        createToast('ERROR', `file size can't be more ${maxSize} mb`)
      );

      return;
    }

    const image = await getBase64(file);
    setThumbnail(image);
    if (base64) {
      const type = files[0].name.split('.').slice(-1)[0];
      onChange([{ image, type }]);
    } else {
      onChange([file]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // const removeFile = (index, e) => {
  //   const { onChange, value } = this.props;
  //   e.preventDefault();
  //   onChange(value.filter((val, i) => i !== index));
  // };

  return (
    <div
      style={{
        width: `${size}px`,
        padding: '0',
        cursor: 'pointer',
        ...style,
      }}
      className="h-100 btn"
      {...getRootProps()}
    >
      <input {...getInputProps()} accept={accept} />
      <Card className={`h-100 card bg-light text-black`}>
        {label && <Card.Header className="pt-0 pb-0">{label}</Card.Header>}
        {thumbnail ? (
          <Card.Img src={thumbnail} className="card-img" alt={label} />
        ) : (
          <>
            <ImagePlusIcon
              size={200}
              color={isDragActive ? '#aaaaaa' : '#dddddd'}
              style={{ alignSelf: 'center', padding: '15px' }}
            />
          </>
        )}
      </Card>
    </div>
  );
};

DropZoneField.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
  ]).isRequired,
  image: PropTypes.string,
  error: PropTypes.string.isRequired,
  touched: PropTypes.bool.isRequired,
};

DropZoneField.defaultProps = {
  image: '',
};

const renderDropZoneField = ({ input, meta, ...props }) => {
  return (
    <DropZoneField
      {...input}
      {...props}
      error={meta.error || ''}
      touched={meta.touched || false}
    />
  );
};

renderDropZoneField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape().isRequired,
};

export default renderDropZoneField;
