import React, { memo } from 'react';
import { Row, Form, Col } from 'react-bootstrap';

export default memo(function ControlledSelect({
  label,
  error,
  type,
  options,
  value,
  onChange,
  className = '',
}) {
  return (
    <Form.Group as={Row}>
      {label && <Col sm={3}>{label}</Col>}
      <Col sm={9} className={className}>
        <Form.Control
          type={type}
          aria-label={label}
          as={'select'}
          value={value}
          onChange={e => {
            onChange(e.target.value);
          }}
        >
          {options.map(({ name, value }, index) => (
            <option value={value} key={`${name}${index}`}>
              {name}
            </option>
          ))}
        </Form.Control>
        {error && <span className="text-danger">{error}</span>}
      </Col>
    </Form.Group>
  );
});
