const systemFiles = ['desktop.ini', '.DS_store', 'Thumbs.db', '.DS_Store'];

export function getEquiFiles(zip) {
  return zip.filter(
    f =>
      f.filename.startsWith('equi') &&
      !f.directory &&
      !f.filename.match(new RegExp(systemFiles.join('|')))
  );
}

function validateBase(zip, filename) {
  const isValidEquiFile = file => {
    return !!/equi\/eq_\d*\.jpg/.test(file);
  };

  const hasEquiFolder = !!zip.find(f => f.filename.startsWith('equi/'));
  const isZip = filename.endsWith('.zip');
  const equiFiles = getEquiFiles(zip);
  const isEquiFilesValid = equiFiles.every(f => isValidEquiFile(f.filename));

  if (!isZip) {
    throw new Error('Incorrect archive format. Only zip archives are allowed.');
  }
  if (!hasEquiFolder) {
    throw new Error(
      'Unable to find equi panomaras in the archive.' +
        ' Please check if panoramas are present and located in "equi" folder.'
    );
  }
  if (!isEquiFilesValid) {
    const incorrectFileNames = equiFiles
      .filter(f => !isValidEquiFile(f.filename))
      .map(f => f.filename);

    const error =
      new Error(`equi panoramas are not present or named incorrectly.
     Please check equi naming, it should be: eq_1.jpg, eq_2.jpg...\n Found ${incorrectFileNames.join(
       ', '
     )}`);
    error.heading = 'Naming error: ';
    throw error;
  }
}

export function validateArchive3d(zip, filename) {
  validateBase(zip, filename);

  const hasGltf = !!zip.find(f => f.filename.startsWith('location.gltf'));
  const hasBin = !!zip.find(f => f.filename.startsWith('location.bin'));
  const meshFiles = zip.filter(
    f =>
      f.filename.startsWith('mesh') &&
      !f.directory &&
      !f.filename.match(new RegExp(systemFiles.join('|')))
  );
  const isValidMeshFiles = meshFiles.every(f =>
    /mesh_?\d+.jpg/.test(f.filename)
  );

  if (!isValidMeshFiles) {
    throw new Error(
      'Naming error: The file extension of all images must be "jpg" with small letters'
    );
  }

  if (!hasGltf) {
    throw new Error('Unable to find location.gltf. Please check your archive.');
  }
  if (!hasBin) {
    throw new Error('Unable to find location bin. Please check your archive.');
  }
}

export function validateArchiveMeshGenerate(zip, filename) {
  const isValidEqFile = file => {
    return !!/eq_\d+\.(exr|jpg)/.test(file);
  };

  const hasCoordTxt = !!zip.find(f => f.filename === 'coord.txt');
  const hasEqFiles = !!zip.find(f => isValidEqFile(f.filename));
  const isZip = filename.endsWith('.zip');

  if (!isZip) {
    throw new Error('Incorrect archive format. Only zip archives are allowed.');
  }
  if (!hasCoordTxt) {
    throw new Error(
      'Unable to find "coord.txt" in the archive. Please check if the file is present.'
    );
  }
  if (!hasEqFiles) {
    throw new Error(
      'Unable to find equi panomaras (eq_{n}.exr or eq_{n}.jpg) in the archive.' +
        ' Please check if panoramas are present and located in the correct format.'
    );
  }

  const eqFiles = zip.filter(
    f => f.filename.match(/eq_\d+\.(exr|jpg)/) && !f.directory
  );

  const eqFileNumbers = new Set(
    eqFiles.map(f => parseInt(f.filename.match(/\d+/)[0]))
  );
  for (const number of eqFileNumbers) {
    const exrFileName = `eq_${number}.exr`;
    const jpgFileName = `eq_${number}.jpg`;
    if (
      !eqFiles.some(f => f.filename === exrFileName) ||
      !eqFiles.some(f => f.filename === jpgFileName)
    ) {
      throw new Error(
        `Missing either ${exrFileName} or ${jpgFileName}. Make sure both files are present.`
      );
    }
  }
}

export function validateArchive2d(zip, filename) {
  validateBase(zip, filename);
}

export const validateFramesZip = (zip, filename) => {
  const isValidHighFile = file => !!/high\/frame_\d+/.test(file);

  const isValidLowFile = file => !!/low\/frame_low_\d+/.test(file);

  const hasHighFolder = !!zip.find(f => f.filename.includes('high/'));
  const hasLowFolder = !!zip.find(f => f.filename.includes('low/'));

  const isZip = filename.endsWith('.zip');

  const highFiles = zip.filter(
    f =>
      f.filename.includes('high/') &&
      !f.directory &&
      !f.filename.match(new RegExp(systemFiles.join('|')))
  );

  const lowFiles = zip.filter(
    f =>
      f.filename.includes('low/') &&
      !f.directory &&
      !f.filename.match(new RegExp(systemFiles.join('|')))
  );

  const isHighFilesValid =
    highFiles.length > 0 && highFiles.every(f => isValidHighFile(f.filename));

  const isLowFilesValid =
    lowFiles.length > 0 && lowFiles.every(f => isValidLowFile(f.filename));

  if (!isZip) {
    throw new Error('Incorrect archive format. Only zip archives are allowed.');
  }

  if (!hasHighFolder || !hasLowFolder) {
    throw new Error(
      'Unable to find required folders ("high" and/or "low") in the archive.' +
        ' Please check if both folders are present.'
    );
  }

  if (highFiles.length === 0) {
    throw new Error(
      'High folder is empty. Please make sure it contains valid files.'
    );
  }

  if (lowFiles.length === 0) {
    throw new Error(
      'Low folder is empty. Please make sure it contains valid files.'
    );
  }

  if (!isHighFilesValid) {
    const incorrectHighFileNames = highFiles
      .filter(f => !isValidHighFile(f.filename))
      .map(f => f.filename);

    const error = new Error(
      `High folder contains invalid files. Please check file naming in "high" folder; it should be: frame_1, frame_2, ... Found ${incorrectHighFileNames.join(
        ', '
      )}`
    );
    error.heading = 'High Folder Naming Error: ';
    throw error;
  }

  if (!isLowFilesValid) {
    const incorrectLowFileNames = lowFiles
      .filter(f => !isValidLowFile(f.filename))
      .map(f => f.filename);

    const error = new Error(
      `Low folder contains invalid files. Please check file naming in "low" folder; it should be: frame_low_1, frame_low_2, ... Found ${incorrectLowFileNames.join(
        ', '
      )}`
    );
    error.heading = 'Low Folder Naming Error: ';
    throw error;
  }
};
