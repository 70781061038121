import React from 'react';
import { Row, Form } from 'react-bootstrap';

const Checkbox = ({ input, label, meta: { touched, error }, type, hide }) => (
  <Form.Group as={Row} className={hide ? 'd-none' : ''}>
    {label && (
      <Form.Label column sm="3">
        {label}
      </Form.Label>
    )}
    <div className="col-sm-9 d-flex align-items-center">
      <Form.Check {...input} type={type} aria-label={label} />
    </div>
    {touched && error && <span>{error}</span>}
  </Form.Group>
);

export default Checkbox;
