import React, { useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import InfoTooltipIcon from '../InfoTooltipIcon';

export default function Input({
  input,
  label,
  tooltip,
  min,
  placeholder,
  type,
  meta: { touched, error },
  as,
  readOnly,
  span,
  hide,
  onChangeValidate,
  children,
  initialValue,
  onUpdateStore,
}) {
  const [value, setValue] = useState(initialValue);
  return (
    <Form.Group as={Row} className={hide ? ' d-none' : ''}>
      {label && (
        <Form.Label column sm={span ? 12 - span : 3}>
          {tooltip ? (
            <div className="explorer3d-field-tooltip">
              {label} <InfoTooltipIcon tooltip={tooltip} />
            </div>
          ) : (
            label
          )}
        </Form.Label>
      )}
      <Col sm={span || 9}>
        <Form.Control
          min={min === undefined ? 1 : min}
          {...input}
          type={type}
          placeholder={placeholder || label}
          aria-label={placeholder || label}
          as={as}
          readOnly={readOnly}
          value={value}
          onChange={e => {
            setValue(e.target.value);
            if (onUpdateStore) {
              onUpdateStore(e.target.value);
            }
            return e.target.value;
          }}
        >
          {children}
        </Form.Control>
        {(onChangeValidate ? true : touched) && error && (
          <span className="text-danger">{error}</span>
        )}
      </Col>
    </Form.Group>
  );
}
