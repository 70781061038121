import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import {
  pipe,
  map,
  splitEvery,
  sort,
  split,
  head,
  filter,
  prop,
  andThen,
  contains,
} from 'ramda';
import JSZip from 'jszip';
import DeleteIcon from 'mdi-react/DeleteIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import MapIcon from 'mdi-react/MapIcon';
import ShareIcon from 'mdi-react/ShareIcon';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import ContentDuplicate from 'mdi-react/ContentDuplicateIcon';
import CameraIcon from 'mdi-react/CameraIcon';
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import InfoTooltipIcon from '../../shared/components/InfoTooltipIcon';

import {
  loadLocationDetails,
  resetLocationDetails,
  updateSettings,
  createToast,
  loadFurnitureList,
  loadHotspotList,
  loadTileStats,
  loadCompanies,
  loadLocationsOfCompany,
} from '../../redux/actions';
import API, { existsOnServer, getLocationById } from '../../shared/utils/API';

import MarzipanoErrorsPopup from './components/MarzipanoErrorsPopup';
import PreviewFileInput from './components/PreviewFileInput';
import MapFileInput from './components/MapFileInput';
import AudioFileInput from './components/AudioFileInput';
import ZipFileInput, { ZipFileInputStorage } from './components/ZipFileInput';
import TileInput, { GreyPage } from './components/TileInput';
import SettingsForm from './components/SettingsForm';
import { PointTypes } from '../../constants';
import ZipBuilder from '../../shared/utils/ZipBuilder';
import { getImageResolution } from '../../shared/utils/parseModel';
import SwitchEditorTypeButton from './components/SwitchEditorTypeButton';
import { buildUrl } from '../../shared/utils/bakeTextures';
import { useDeleteLocation } from '../Companies/components/LocationItem/hooks';
import { MagicTokenSingleton } from '../../shared/utils/MagicTokenSingleton';
import GTLFBuilderInput from './components/GTLFBuilderInput';
import PlayIcon from 'mdi-react/PlayIcon';
import FrameZipInput from './components/FrameZipInput';

const useVersionFetch = (initialUrl, initialData) => {
  const [data, setData] = useState(['1.0.0']);
  const [url, setUrl] = useState(
    `${process.env.REACT_APP_BACKEND_URL}/api/get-offline-player-versions`
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const result = await fetch(url);

        if (result.ok) {
          setData(await result.json());
        } else {
          setData(['1.0.0']);
        }
      } catch (e) {
        setData(['1.0.0']);
      }

      setIsLoading(false);
    };

    fetchData();
  }, [url]);

  return { data, isLoading };
};

const InputType = {
  FULL_LOCATION: 'refresh the whole location',
  MESH_ONLY: 'refresh the mesh',
};

const LocationSettings = ({
  match,
  location,
  settings,
  furnitureList,
  hotspotList,
  auth,
  tiles,
  loadLocationDetails,
  loadFurnitureList,
  loadHotspotList,
  loadTileStats,
  resetLocationDetails,
  updateSettings,
  createToast,
  companies,
  companyData,
  loadCompanies,
  loadLocationsOfCompany,
}) => {
  const history = useHistory();

  if (location.loaded && !location.data) {
    history.replace('/404');

    return null;
  }

  const [points, setPoints] = useState([]);
  const [instantPanoMinimapPoints, setInstantPanoMinimapPoints] = useState([]);
  const [minimapPoints, setMinimapPoints] = useState([]);
  const [furnitureItems, setFurnitureItems] = useState([]);
  const [hotspotItems, setHotspotItems] = useState([]);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [zipUploading, setZipUploading] = useState(false);
  const [zipUploadingText, setZipUploadingText] = useState('');
  const [tileUploading, setTileUploading] = useState(false);
  const [tileAllowUploading, setTileAllowUploading] = useState(false);
  const [tileUploadError, setTileUploadError] = useState('');
  const [tileStatsWatch, setTileStatsWatch] = useState(false);
  const [previewUploading, setPreviewUploading] = useState(false);
  const [mapUploading, setMapUploading] = useState(false);
  const [isEditor2Show, setIsEditor2Show] = useState(false);
  const [isEditorPointsMiniMap, setIsEditorPointsMiniMap] = useState(false);
  const [audioUploading, setAudioUploading] = useState(false);
  const [modelParsed, setModelParsed] = useState(0);
  const [modelUploaded, setModelUploaded] = useState(false);
  const [modelUploadingProgress, setModelUploadingProgress] = useState(0);
  const [photoTourChecked, setPhotoTourChecked] = useState(false);
  const [isFloorPlanChecked, setIsFloorPlanChecked] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [tilesUploaded, setTilesUploaded] = useState(false);
  const [resetTilesStatsProgress, setResetTilesStatsProgress] = useState(0);
  const [hasLocation, setHasLocation] = useState(false);
  const [meshGenerationQueuePos, setMeshGenerationQueuePos] = useState(null);
  const deleteLocation = useDeleteLocation({
    locationId: location.data ? location.data.id : 0,
    locationName: location.data ? location.data.description.LOCATION_NAME : '',
    companyName: location.data ? location.data.description.COMPANY_NAME : '',
    goBack: true,
  });
  const { data: versions, isLoading: versionsLoading } = useVersionFetch();
  const [framesUploading, setFramesUploading] = useState(false);
  const [framesAllowUploading, setFramesAllowUploading] = useState(true);
  const [framesUploadError, setFramesUploadError] = useState(null);
  const [framesUploaded, setFramesUploaded] = useState(false);

  useEffect(() => {
    if (!location.loaded) return;
    loadCompanies();
    loadLocationsOfCompany(location.data.company_name);

    console.log(location, modelUploaded);

    setPhotoTourChecked(location.data.description.INSTANT_PANO_ONLY);
    setIsFloorPlanChecked(location.data.description.IS_INTERACTIVE_PLAN);
  }, [location]);

  useEffect(() => {
    const checkLocation = async () => {
      if (!location.loaded) {
        return;
      }
      const id = location.data.description.PARENT_VARIANT
        ? location.data.description.PARENT_VARIANT
        : location.data.id;

      await new Promise(rs => setTimeout(rs, 3000));

      const assetsExists = await Promise.all([
        existsOnServer(buildUrl(id, 'location.gltf')),
        existsOnServer(buildUrl(id, 'location.bin')),
        existsOnServer(buildUrl(id, 'equi/eq_1.jpg')),
      ]);

      setHasLocation(assetsExists.every(asset => asset === true));
    };

    const interval = setInterval(checkLocation, 2000);

    return () => clearInterval(interval);
  }, [location]);

  ZipBuilder.location = location;

  const floors = useMemo(() => {
    if (!location.loaded) return [];

    const floorsSet = new Set();

    for (const point of location.data.description.LOCATION_POINT_SHORT_INFO) {
      floorsSet.add(Math.floor(+point.floor));
    }

    return Array.from(floorsSet).sort((a, b) => a - b);
  }, [location]);

  const id = match.params.id;
  const reload = useCallback(() => loadLocationDetails(id), [id]);
  const togglePlayer = () => {
    setIsEditorPointsMiniMap(false);
    updateSettings({ showPlayer: !settings.showPlayer });
    reload();
  };

  const updateCubemapResolution = async res => {
    // eslint-disable-next-line no-unused-expressions
    location.data.description.CUBEMAP_RESOLUTION = res;

    const {
      editorNeedSync,
      INSTANT_PANO_LOCATION_POINT_SHORT_INFO,
      LOCATION_POINT_SHORT_INFO,
      NORTH_DIRECTION,
    } = await API.getLocationDescription(id);

    await API.request(API.endpoints.UPDATE_LOCATION_FULL, {
      locationId: location.data.id,
      name: location.data.name,
      companyName: location.data.company_name,
      description: `'${JSON.stringify({
        ...location.data.description,
        ...(editorNeedSync
          ? {
              LOCATION_POINT_SHORT_INFO,
              INSTANT_PANO_LOCATION_POINT_SHORT_INFO,
              NORTH_DIRECTION,
            }
          : {}),
        editorNeedSync: false,
      })}'`,
    });
  };

  useEffect(() => {
    if (id) {
      updateSettings({ showPlayer: false }); // hide minimap/playback editor
      loadLocationDetails(id);
    }

    return () => resetLocationDetails();
  }, [match.params.id]);

  useEffect(() => {
    if (
      !furnitureList.length &&
      (furnitureItems.length ||
        (location.loaded &&
          Object.keys(location.data.description.FURNITURE || {}).length))
    ) {
      loadFurnitureList();
    }
  }, [furnitureItems, location]);

  useEffect(() => {
    if (!furnitureItems.length && location.loaded) {
      setFurnitureItems(Object.keys(location.data.description.FURNITURE || {}));
    }
  }, [location]);

  useEffect(() => {
    if (!hotspotList.length) {
      loadHotspotList();
    }
  }, [hotspotList, loadHotspotList]);

  useEffect(() => {
    if (!hotspotItems.length && location.loaded) {
      setHotspotItems(Object.keys(location.data.description.HOTSPOT || {}));
    }
  }, [location]);

  useEffect(() => loadTileStats(id), [id]);

  useEffect(() => {
    if (tiles.active) {
      setTileStatsWatch(true);
    }
  }, [tiles]);

  useEffect(() => {
    let timeout;
    if (tileStatsWatch) {
      timeout = setTimeout(() => loadTileStats(id), 500);
    }

    return () => clearTimeout(timeout);
  }, [tileStatsWatch, tiles, id]);

  useEffect(() => {
    const fetchData = () => {
      fetch(
        `${process.env.REACT_APP_MESHGEN_URL}/getMeshGenerationStatus/${id}`
      )
        .then(r => r.json())
        .then(r => setMeshGenerationQueuePos(r))
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    };
    fetchData();
    const interval = setInterval(fetchData, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const savedProgress = Number(
      localStorage.getItem(`cubemap-progress-${id}`)
    );
    if (savedProgress && tiles.stats && !tiles.stats.active) {
      setTileStatsWatch(false);
      localStorage.setItem(`cubemap-progress-${id}`, '0');
    } else setTileStatsWatch(true);
  }, [tiles]);

  const updateDescriptionHandler = useCallback(
    async ({ LOCATION_NAME, COMPANY_NAME, ...values }) => {
      try {
        if (!values.LOCATION_POINT_SHORT_INFO) return;
        const points = values.LOCATION_POINT_SHORT_INFO.filter(
          p => p !== null
        ).map(p => {
          return {
            name: p.name,
            x: Number(p.x) || 0,
            y: Number(p.y) || 0,
            cam: {
              x: (p.cam && p.cam.x) || 0,
              y: (p.cam && p.cam.y) || 0,
              z: (p.cam && p.cam.z) || 0,
            },
            floor: p.floor ? Number(p.floor) : p.cam.y > 300 ? 2 : 1,
            target: p.target || undefined,
            noImage: p.noImage || undefined,
            icon: p.icon || undefined,
            hotspotId: p.hotspotId || undefined,
            portalId: Number(p.portalId) || undefined,
            portalPoint: Number(p.portalPoint) || undefined,
            scale: Number(p.scale) || 1,
            arrows: p.arrows,
            markers: p.markers ? p.markers.filter(m => m !== null) : undefined,
          };
        });

        const instantPanoMinimapPoints = (
          values.INSTANT_PANO_LOCATION_POINT_SHORT_INFO || []
        )
          .filter(p => p !== null)
          .map(p => {
            p.cam = {
              x: Number(p.x || 0),
              y: Number(p.y || 0),
              z: Number(p.z || 0),
            };

            return {
              name: p.name,
              x: Number(p.x) || 0,
              y: Number(p.y) || 0,
              cam: p.cam,
              floor: p.cam.y > 300 ? 2 : 1,
              target: p.target || undefined,
              noImage: p.noImage || undefined,
              icon: p.icon || undefined,
              hotspotId: p.hotspotId || undefined,
              portalId: Number(p.portalId) || undefined,
              portalPoint: Number(p.portalPoint) || undefined,
              scale: Number(p.scale) || 1,
              arrow: p.arrows,
              markers: p.markers
                ? p.markers.filter(m => m !== null)
                : undefined,
            };
          });

        const {
          editorNeedSync,
          INSTANT_PANO_LOCATION_POINT_SHORT_INFO,
          LOCATION_POINT_SHORT_INFO,
          NORTH_DIRECTION,
        } = await API.getLocationDescription(id);

        // const isLocationUpdate = points.length === values.LOCATION_POINT_SHORT_INFO;

        const description = `'${JSON.stringify({
          LOCATION_NAME: LOCATION_NAME || location.data.name,
          COMPANY_NAME,
          ...values,
          LOCATION_POINT_SHORT_INFO: editorNeedSync
            ? LOCATION_POINT_SHORT_INFO
            : points,
          INSTANT_PANO_LOCATION_POINT_SHORT_INFO: editorNeedSync
            ? INSTANT_PANO_LOCATION_POINT_SHORT_INFO
            : instantPanoMinimapPoints,
          NORTH_DIRECTION,
          TOOLBAR_VALUE: values.TOOLBAR_VALUE,
          VARIANTS:
            values.VARIANTS && values.VARIANTS.length > 0
              ? [
                  ...new Set([
                    ...values.VARIANTS.map(v => Number(v)).filter(v => !!v),
                  ]),
                ]
              : undefined,
          editorNeedSync: false,
        })}'`;

        const data = {
          locationId: id,
          name: LOCATION_NAME,
          companyName: COMPANY_NAME,
          description,
          date: values.CREATED_AT,
        };
        console.log('Update');
        console.log(data);
        await API.request(API.endpoints.UPDATE_LOCATION_FULL, data);
        reload();
        createToast('SUCCESS', 'Location has been updated');
      } catch (error) {
        console.error(error);
        createToast('ERROR', error.toString());
      }
    },
    [location]
  );

  const updateParentGLTFFlagHandler = useCallback(async () => {
    try {
      if (!location.data.description.USE_PARENT_GLTF) {
        return;
      }

      await API.request(API.endpoints.UPDATE_LOCATION_FULL, {
        locationId: location.data.id,
        name: location.data.name,
        companyName: location.data.company_name,
        description: `'${JSON.stringify({
          ...location.data.description,
          USE_PARENT_GLTF: false,
          editorNeedSync: false,
        })}'`,
      });
      updateSettings({ hash: String(Date.now()) });
      reload();
      createToast('SUCCESS', 'Successfully detached location from parent');
    } catch (error) {
      console.error(error);
      createToast('ERROR', error.toString());
    }
  }, [location]);

  const updatePreviewHandler = useCallback(
    async file => {
      const { image, type } = file.preview[0];
      setPreviewUploading(true);
      try {
        if (image) {
          const data = {
            id: location.data.id,
            file: image.slice(image.indexOf(',') + 1),
            type,
          };

          const {
            editorNeedSync,
            INSTANT_PANO_LOCATION_POINT_SHORT_INFO,
            LOCATION_POINT_SHORT_INFO,
            NORTH_DIRECTION,
          } = await API.getLocationDescription(id);

          await API.request(API.endpoints.UPLOAD_PREVIEW, data);
          await API.request(API.endpoints.UPDATE_LOCATION_FULL, {
            locationId: location.data.id,
            name: location.data.name,
            companyName: location.data.company_name,
            description: `'${JSON.stringify({
              ...location.data.description,
              ...(editorNeedSync
                ? {
                    LOCATION_POINT_SHORT_INFO,
                    INSTANT_PANO_LOCATION_POINT_SHORT_INFO,
                    NORTH_DIRECTION,
                  }
                : {}),
              PREVIEW_IMAGE: `locations/${location.data.id}/preview.jpg`,
              editorNeedSync: false,
            })}'`,
          });
          updateSettings({ hash: String(Date.now()) });
          setPreviewUploading(false);
          reload();
          createToast('SUCCESS', 'Preview image has been uploaded');
        } else {
          console.info('image:', image);
          throw new Error(
            `updatePreviewHandler: something wrong with an image`
          );
        }
      } catch (error) {
        console.error(error);
        setPreviewUploading(false);
        createToast('ERROR', error.toString());
      }
    },
    [location]
  );

  const updateAudioHandler = useCallback(
    async file => {
      console.log('file', file);
      setAudioUploading(true);
      try {
        if (file) {
          const {
            editorNeedSync,
            INSTANT_PANO_LOCATION_POINT_SHORT_INFO,
            LOCATION_POINT_SHORT_INFO,
            NORTH_DIRECTION,
          } = await API.getLocationDescription(id);

          const type = file.name.split('.').pop();
          const fd = new FormData();
          fd.append('id', location.data.id);
          fd.append('file', file);
          fd.append('type', type);
          await API.request(API.endpoints.UPLOAD_AUDIO, fd);
          await API.request(API.endpoints.UPDATE_LOCATION_FULL, {
            locationId: location.data.id,
            name: location.data.name,
            companyName: location.data.company_name,
            description: `'${JSON.stringify({
              ...location.data.description,
              ...(editorNeedSync
                ? {
                    LOCATION_POINT_SHORT_INFO,
                    INSTANT_PANO_LOCATION_POINT_SHORT_INFO,
                    NORTH_DIRECTION,
                  }
                : {}),
              AUDIO: `locations/${location.data.id}/audio.${type}`,
              editorNeedSync: false,
            })}'`,
          });
          console.log('UPLOAD_AUDIO', id, file, type);
          updateSettings({ hash: String(Date.now()) });
          reload();
          createToast('SUCCESS', 'Audio has been uploaded');
        } else {
          console.info('audio:', file);
          throw new Error(`updateAudioHandler: something wrong with an audio`);
        }
      } catch (error) {
        console.error(error);
        createToast('ERROR', error.toString());
      }
    },
    [location]
  );

  const updateMapHandler = useCallback(
    async files => {
      setMapUploading(true);
      try {
        if (files.length) {
          const images = files
            .map((file, floor) => {
              const { image, type } = file[0];

              return {
                floor,
                type,
                file: image.slice(image.indexOf(',') + 1),
              };
            })
            .filter(item => !!item);
          const data = {
            id: location.data.id,
            images,
          };

          const originalPaths = location.data.description.MAP_IMAGES || {};
          const paths = images.reduce(
            (result, { floor }) => ({
              ...result,
              [floor]: `locations/${location.data.id}/maps/${floor}.png`,
            }),
            {}
          );

          await API.request(API.endpoints.UPLOAD_MAP, data);

          const url =
            process.env.REACT_APP_MEDIA_URL + '/' + Object.values(paths)[0];
          const { width, height } = await getImageResolution(url);
          const aspectRatio = width / height;

          const points = location.data.description.LOCATION_POINT_SHORT_INFO;

          for (const point of points) {
            if (point.normX && point.normY) {
              const xFixed = point.normX / aspectRatio;
              const yFixed = point.normY;
              const px = xFixed * 0.5 + 0.5;
              const py = yFixed * 0.5 + 0.5;

              point.x = Math.floor(px * width);
              point.y = Math.floor(height - py * height);
            }
          }

          const {
            editorNeedSync,
            INSTANT_PANO_LOCATION_POINT_SHORT_INFO,
            LOCATION_POINT_SHORT_INFO,
            NORTH_DIRECTION,
          } = await API.getLocationDescription(id);

          await API.request(API.endpoints.UPDATE_LOCATION_FULL, {
            locationId: location.data.id,
            name: location.data.name,
            companyName: location.data.company_name,
            description: `'${JSON.stringify({
              ...location.data.description,
              ...(editorNeedSync
                ? {
                    LOCATION_POINT_SHORT_INFO,
                    INSTANT_PANO_LOCATION_POINT_SHORT_INFO,
                    NORTH_DIRECTION,
                  }
                : {}),
              MAP_IMAGES: {
                ...originalPaths,
                ...paths,
              },
              editorNeedSync: false,
            })}'`,
          });

          setMapUploading(false);
          reload();
          createToast(
            'SUCCESS',
            `Map image${files.length > 1 ? 's' : ''} has been uploaded`
          );
        } else {
          console.info('files:', files);
          throw new Error(`updateMapHandler: something wrong with files`);
        }
      } catch (error) {
        console.error(error);
        setMapUploading(false);
        createToast('ERROR', error.toString());
      }
    },
    [location]
  );

  const updateZipHandler = useCallback(
    async (zip, generateMesh, meshOptions) => {
      setZipUploadingText(null);
      setZipUploading(true);

      try {
        if (zip) {
          const fd = new FormData();
          fd.append('id', location.data.id);
          setModelUploadingProgress(0);

          if (generateMesh) {
            fd.append('file', zip);
            fd.append('json_data', JSON.stringify(meshOptions));
            fd.append('token', `Bearer ${MagicTokenSingleton.I.getToken()}`);
            await API.requestWithXHR(
              API.endpoints.MESH_GENERATION_UPLOAD,
              fd,
              setModelUploadingProgress
            );
          } else {
            const finalZip = await ZipBuilder.build(
              zip,
              ZipFileInputStorage.newFiles
            );
            fd.append('file', finalZip);
            ZipFileInputStorage.newFiles = [];
            await API.requestWithXHR(
              API.endpoints.UPLOAD_MODEL,
              fd,
              setModelUploadingProgress
            );
          }
          setModelUploaded(true);
          setZipUploadingText(null);
          setModelUploadingProgress(0);

          setZipUploading(false);
          createToast('SUCCESS', 'Zip file has been uploaded');
        } else {
          throw new Error(`updateZipHandler: zip is ${zip}`);
        }
      } catch (error) {
        console.error(error);
        setZipUploading(false);
        createToast('ERROR', error.toString());
      }
    },
    [points, location, minimapPoints]
  );

  const cancelTileHandler = useCallback(async () => {
    try {
      await API.request(
        API.endpoints.CANCEL_TILES,
        null,
        `?location=${location.data.id}`
      );
      setTileStatsWatch(true);
    } catch (error) {
      console.error(error);
      console.log('[cancelTileHandler] something wrong');
      createToast('ERROR', error.message);
    }
  }, [location]);

  const uploadTileHandler = useCallback(
    async ({
      files,
      compress,
      resolutions,
      withoutSlicing,
      sliceOnlyCubemaps,
      slicingQuality,
    }) => {
      setTileUploading(true);
      setTileAllowUploading(false);

      try {
        if (files && files.length) {
          const awaitSequence = fn => args =>
            // avoiding point-free for clarity
            args.reduce(async (prevFn, nextArg) => {
              await prevFn;

              return fn(nextArg);
            }, Promise.resolve());

          const pointNumber = pipe(prop('name'), split('_'), head, Number);
          const isValidFilename = value => () => value.startsWith('eq_');
          const isFrontSide = pipe(prop('name'), contains('cubefront'));
          const comparePoints = (a, b) => pointNumber(a) - pointNumber(b);

          const makeEquiZip = files => {
            if (
              files.length === 1 &&
              ['.zip', '.rar'].some(ext => files[0].name.includes(ext))
            ) {
              return Promise.resolve(files[0]);
            }

            const path = 'equi';
            const zip = new JSZip();
            files.forEach(file => zip.file(`${path}/${file.name}`, file));

            return zip.generateAsync({ type: 'blob' });
          };

          const makeSlicingFormData =
            (id, resolutions, compress, slicingQuality) => files => {
              const fd = new FormData();
              fd.append('location', id);
              fd.append('resolutions', resolutions.join(','));
              fd.append('quality', slicingQuality);
              fd.append('preview', true);
              if (compress) fd.append('compress', true);
              for (const file of files) {
                fd.append('file', file);
              }

              return fd;
            };

          const makeCubemapsFormData = id => files => {
            const fd = new FormData();
            fd.append('location', id);
            fd.append('onlyCubemaps', true);
            if (compress) fd.append('compress', true);
            for (const file of files) {
              fd.append('file', file);
            }

            return fd;
          };

          const makePreviewFormData = id => files => {
            const fd = new FormData();
            fd.append('location', id);
            fd.append('preview', true);
            for (const file of files) {
              fd.append('file', file);
            }

            return fd;
          };

          const makeSimpleFormData = id => file => {
            const fd = new FormData();
            fd.append('id', id);
            fd.append('file', file);

            return fd;
          };

          const makeSlicingRequest = fd =>
            API.request(API.endpoints.UPLOAD_TILES, fd);
          const makeSimpleRequest = fd =>
            API.request(API.endpoints.UPLOAD_EQUI, fd);

          if (withoutSlicing) {
            const uploadSimple = pipe(
              filter(isValidFilename),
              sort(comparePoints),
              splitEvery(1),
              awaitSequence(
                pipe(
                  makeEquiZip,
                  andThen(makeSimpleFormData(location.data.id)),
                  andThen(makeSimpleRequest)
                )
              )
            );

            await uploadSimple(files);

            setResetTilesStatsProgress(x => x + 1);
            // await uploadPreviews(files);
            setTileStatsWatch(false);
            setTileUploading(false);
            setTilesUploaded(true);
            createToast('SUCCESS', 'Panos has been uploaded');
          } else if (sliceOnlyCubemaps) {
            const uploadWithSlicing = pipe(
              filter(isValidFilename),
              sort(comparePoints),
              splitEvery(1000),
              map(makeCubemapsFormData(location.data.id)),
              // sending requests sequentially
              // to preserve point order
              awaitSequence(makeSlicingRequest)
            );

            await uploadWithSlicing(files);
            setTileStatsWatch(true);
            setTileUploading(false);
            setTilesUploaded(true);
            createToast('SUCCESS', 'Tiles has been uploaded');
          } else {
            const uploadWithSlicing = pipe(
              filter(isValidFilename),
              sort(comparePoints),
              splitEvery(1000),
              map(
                makeSlicingFormData(
                  location.data.id,
                  resolutions,
                  compress,
                  slicingQuality
                )
              ),
              // sending requests sequentially
              // to preserve point order
              awaitSequence(makeSlicingRequest)
            );

            await uploadWithSlicing(files);
            setTileStatsWatch(true);
            setTileUploading(false);
            setTilesUploaded(true);
            createToast('SUCCESS', 'Tiles has been uploaded');
          }
        } else {
          const makeSlicingFormData = (
            id,
            resolutions,
            compress,
            slicingQuality,
            sliceOnlyCubemaps
          ) => {
            const fd = new FormData();
            fd.append('location', id);
            fd.append('resolutions', resolutions.join(','));
            fd.append('quality', slicingQuality);
            if (sliceOnlyCubemaps) fd.append('onlyCubemaps', sliceOnlyCubemaps);
            fd.append('preview', true);
            if (compress) fd.append('compress', true);

            return fd;
          };

          await API.request(
            API.endpoints.UPLOAD_TILES,
            makeSlicingFormData(
              location.data.id,
              resolutions,
              compress,
              slicingQuality,
              sliceOnlyCubemaps
            )
          );
          setTileStatsWatch(true);
          setTileUploading(false);
          setTilesUploaded(true);

          const data = {
            locationId: location.data.id,
            name: location.data.name,
            companyName: location.data.company_name,
            description: `'${JSON.stringify({
              ...location.data.description,
              USE_TILED_CUBEMAPS: true,
            })}'`,
          };

          await API.request(API.endpoints.UPDATE_LOCATION_FULL, data);
          await reload();

          // throw new Error(`Please select files`);
        }
      } catch (error) {
        console.error(error);
        console.log('[uploadTileHandler] files to upload:', files);
        setTileUploading(false);
        setTileUploadError(error.message);
        createToast('ERROR', error.message);
      }
    },
    [location, points]
  );

  const uploadFramesZipHandler = useCallback(
    async ({ files }) => {
      setFramesUploading(true);
      setFramesAllowUploading(false);

      try {
        const makeFormData = id => {
          const fd = new FormData();
          fd.append('id', id);
          fd.append('file', files);

          return fd;
        };

        await API.request(
          API.endpoints.UPLOAD_FRAMES,
          makeFormData(location.data.id)
        );
        setFramesUploading(false);
        setFramesUploaded(true);
        createToast('SUCCESS', 'Frames has been uploaded');

        const data = {
          locationId: location.data.id,
          name: location.data.name,
          companyName: location.data.company_name,
          description: `'${JSON.stringify({
            ...location.data.description,
            FRAMES_UPLOADED: true,
          })}'`,
        };

        await API.request(API.endpoints.UPDATE_LOCATION_FULL, data);
        await reload();
      } catch (error) {
        setFramesUploading(false);
        setFramesUploaded(false);
        setFramesUploadError(error.message);
        createToast('ERROR', error.message);
      }
    },
    [location]
  );

  const createLocationCopy = async () => {
    const result = await API.request(API.endpoints.COPY_LOCATION, {
      locationId: location.data.id,
    });

    window.location.href =
      window.location.origin + '/location/' + result.result.id;
  };

  const copyAsVariant = async () => {
    const res = await API.request(API.endpoints.COPY_LOCATION, {
      locationId: location.data.id,
      mode: 'VARIANT',
    });

    const copyRes = await getLocationById(res.result.id);
    const copy = copyRes.result[0];

    copy.description.PARENT_VARIANT = location.data.id;
    copy.description.USE_PARENT_GLTF = true;
    copy.description = `'${JSON.stringify(copy.description)}'`;

    const updates = [
      API.request(API.endpoints.UPDATE_LOCATION_FULL, {
        ...copy,
        locationId: copy.id,
      }),
    ];

    await Promise.all(updates);

    const originalRecord = await API.request(
      API.endpoints.GET_ACTIVE_RECORD,
      null,
      `?locationId=${location.data.id}`
    );

    if (!originalRecord.result.message) {
      window.location.href =
        window.location.origin + '/location/' + res.result.id;

      return;
    }

    const data = {
      locationId: copy.id,
      path: originalRecord.result.message.path,
      isActive: 1,
    };
    await API.request(API.endpoints.ADD_RECORD, data);

    window.location.href =
      window.location.origin + '/location/' + res.result.id;
  };

  const handleExportSubmit = e => {
    e.preventDefault();

    const formdata = new FormData(e.target);
    const { version, platform } = Object.fromEntries(
      Array.from(formdata.entries())
    );

    window.open(
      `${process.env.REACT_APP_BACKEND_URL}/api/extractLocationsFromEntry/?entryLocationId=${id}&version=${version}&platform=${platform}`
    );
  };

  if (versionsLoading) return null;

  if (!auth.authenticated) {
    return <Redirect to="/" />;
  }

  if (!location.loaded) {
    return '';
  }

  if (versionsLoading) return '';

  const canCopyAsVariant = () => {
    return !Number.isInteger(location.data.description.PARENT_VARIANT);
  };

  return (
    <div
      className={
        isEditor2Show || settings.showPlayer || isEditorPointsMiniMap
          ? 'location-settings__container'
          : ''
      }
      style={{ backgroundColor: '#e3e3e3' }}
    >
      <Container
        fluid={settings.showPlayer || isEditor2Show}
        className={
          settings.showPlayer || isEditor2Show
            ? 'location-settings__editor-container'
            : 'location-settings__header'
        }
        style={
          settings.showPlayer || isEditor2Show
            ? {}
            : { maxWidth: '1500px', borderRadius: '5px', marginBottom: '20px' }
        }
      >
        <Row
          style={{ maxWidth: '1500px', padding: '10px' }}
          className={
            !settings.showPlayer && !isEditor2Show
              ? 'mt-3 mb-3'
              : 'location-settings__editor-row'
          }
        >
          <Col className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
            <div className="d-flex flex-column flex-sm-row justify-content-start align-items-center">
              <h1
                className="mb-0 mr-3"
                style={{ maxWidth: '900px', wordWrap: 'break-word' }}
              >
                {location.data.name}
              </h1>
              {!isFloorPlanChecked && (
                <>
                  <SwitchEditorTypeButton
                    variant={`${!settings.showPlayer ? 'outline-' : ''}success`}
                    clickHandler={() => {
                      setIsEditor2Show(false);
                      togglePlayer();
                    }}
                    tooltip="Points Editor"
                  >
                    <EyeIcon />
                  </SwitchEditorTypeButton>
                  {!photoTourChecked && (
                    <SwitchEditorTypeButton
                      variant={`${
                        !isEditorPointsMiniMap ? 'outline-' : ''
                      }success`}
                      clickHandler={() => {
                        if (isEditorPointsMiniMap) {
                          reload();
                        }

                        setIsEditorPointsMiniMap(prev => !prev);
                      }}
                      tooltip="Minimap Editor"
                    >
                      <MapIcon />
                    </SwitchEditorTypeButton>
                  )}
                  <SwitchEditorTypeButton
                    variant={isEditor2Show ? 'success' : 'outline-success'}
                    clickHandler={() => {
                      if (settings.showPlayer) {
                        togglePlayer();
                      }
                      setIsEditor2Show(!isEditor2Show);
                    }}
                    tooltip="Autoplay Editor"
                  >
                    <CameraIcon />
                  </SwitchEditorTypeButton>
                </>
              )}
              <SwitchEditorTypeButton
                variant="success"
                clickHandler={() => setShowSharePopup(true)}
                tooltip="Iframe Code"
              >
                <ShareIcon />
              </SwitchEditorTypeButton>
              {!isFloorPlanChecked && (
                <SwitchEditorTypeButton
                  variant="outline-success"
                  clickHandler={() => createLocationCopy()}
                  tooltip="Duplicate Location"
                >
                  <ContentCopyIcon />
                </SwitchEditorTypeButton>
              )}
              <SwitchEditorTypeButton
                variant="outline-success"
                clickHandler={() =>
                  window.open(
                    isFloorPlanChecked
                      ? `${process.env.REACT_APP_PLAYER_URL}/viewer-360?locationId=${location.data.id}`
                      : `${process.env.REACT_APP_PLAYER_URL}/?locationId=${location.data.id}`,
                    '_blank',
                    'noreferrer'
                  )
                }
                tooltip="Open Location in new tab"
              >
                <PlayIcon />
              </SwitchEditorTypeButton>
              {canCopyAsVariant() && !isFloorPlanChecked && (
                <SwitchEditorTypeButton
                  variant="outline-success"
                  clickHandler={() => copyAsVariant()}
                  tooltip="Create Children Location"
                >
                  <ContentDuplicate />
                </SwitchEditorTypeButton>
              )}
              <SwitchEditorTypeButton
                variant="outline-danger"
                clickHandler={() => deleteLocation()}
                tooltip="Delete Location"
              >
                <DeleteIcon />
              </SwitchEditorTypeButton>
              <div className="mr-2">
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() =>
                    document.getElementById('submit-settings-button').click()
                  }
                >
                  Save settings
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {isEditor2Show && (
        <Container className="location-settings__player location-settings__player--fullscreen">
          <Row>
            <Col className="d-flex justify-content-center">
              <iframe
                allow="display-capture"
                allowFullScreen
                title="editor2"
                src={`${process.env.REACT_APP_PLAYER_URL}/editor2?locationId=${
                  location.data.id
                }${
                  location.data.description.INSTANT_PANO_ONLY
                    ? `&key=${process.env.REACT_APP_TOKEN}`
                    : ''
                }`}
                width="800"
                height="500"
                className="location-settings__player-frame mb-3 w-100"
              />
            </Col>
          </Row>
        </Container>
      )}
      {!photoTourChecked && isEditorPointsMiniMap && (
        <Container className="location-settings__player location-settings__player--fullscreen bg-light">
          <Row>
            <Col className="d-flex justify-content-center">
              <iframe
                allowFullScreen
                title="mini-map"
                src={`${
                  process.env.REACT_APP_PLAYER_URL
                }/point-editor?locationId=${location.data.id}${
                  location.data.description.INSTANT_PANO_ONLY
                    ? `&key=${process.env.REACT_APP_TOKEN}`
                    : ''
                }`}
                style={{
                  width: 900,
                  // height: 640,
                }}
                className="location-settings__player-frame mb-3"
              />
            </Col>
          </Row>
        </Container>
      )}
      {settings.showPlayer && (
        <Container className="location-settings__player location-settings__player--fullscreen">
          <Row>
            <Col className="d-flex justify-content-center">
              <iframe
                allowFullScreen
                title="player"
                src={`${process.env.REACT_APP_PLAYER_URL}/editor?locationId=${
                  location.data.id
                }${
                  location.data.description.INSTANT_PANO_ONLY
                    ? `&key=${process.env.REACT_APP_TOKEN}`
                    : ''
                }`}
                width="800"
                height="500"
                className="location-settings__player-frame mb-3 w-100"
              />
              {/* <img
                src={`${process.env.PUBLIC_URL}/img/frame.jpg`}
                className="location-settings__player-frame mb-3 w-100"
                alt="frame"
              /> */}
            </Col>
          </Row>
        </Container>
      )}

      {!settings.showPlayer && !isEditor2Show && !isEditorPointsMiniMap && (
        <Container
          className="location-settings__controls"
          style={{ maxWidth: '1530px', marginBottom: 0 }}
        >
          <GreyPage>
            <SettingsForm
              companies={companies}
              companyData={companyData}
              loadLocationDetails={loadLocationDetails}
              location={location}
              match={match}
              locationId={id}
              enableReinitialize={true}
              onChangePhotoTour={setPhotoTourChecked}
              onSubmit={updateDescriptionHandler}
              updateParentGLTFFlagHandler={updateParentGLTFFlagHandler}
              points={points}
              instantPanoMinimapPoints={instantPanoMinimapPoints}
              furnitureItems={furnitureItems}
              furnitureList={furnitureList}
              hotspotItems={hotspotItems}
              hotspotList={hotspotList}
              modelParsed={modelParsed}
              setShowSharePopup={setShowSharePopup}
              showSharePopup={showSharePopup}
              initialValues={{
                COMPANY_NAME: location.data.company_name,
                ...location.data.description,
              }}
              onPointCountChange={count => {
                const points =
                  location.data.description.LOCATION_POINT_SHORT_INFO;

                if (points.length < count) {
                  for (let i = points.length; i < count; i++) {
                    points.push({
                      cam: { x: Math.random(), y: 0, z: 0 },
                      floor: 1,
                      markers: [],
                      name: 'Point №' + (i + 1),
                      x: 0,
                      y: 0,
                    });

                    location.data.description.LOCATION_POINT_SHORT_INFO =
                      points;
                  }
                } else if (points.length > count) {
                  location.data.description.LOCATION_POINT_SHORT_INFO =
                    points.slice(0, count);
                }

                const minmapPoints =
                  location.data.description
                    .INSTANT_PANO_LOCATION_POINT_SHORT_INFO || [];

                if (minmapPoints.length < count) {
                  for (let i = minmapPoints.length; i < count; i++) {
                    minmapPoints.push({
                      cam: { x: Math.random(), y: 0, z: 0 },
                      floor: 1,
                      markers: [],
                      name: 'Point №' + (i + 1),
                      x: 0,
                      y: 0,
                    });
                  }

                  location.data.description.INSTANT_PANO_LOCATION_POINT_SHORT_INFO =
                    minmapPoints;
                } else if (minmapPoints.length > count) {
                  location.data.description.INSTANT_PANO_LOCATION_POINT_SHORT_INFO =
                    minmapPoints.slice(0, count);
                }

                // setInstantPanoMinimapPoints([...location.data.description.INSTANT_PANO_LOCATION_POINT_SHORT_INFO]);
              }}
              onGltfUpdate={async points => {
                try {
                  const {
                    editorNeedSync,
                    INSTANT_PANO_LOCATION_POINT_SHORT_INFO,
                    LOCATION_POINT_SHORT_INFO,
                    NORTH_DIRECTION,
                  } = await API.getLocationDescription(id);
                  const data = {
                    locationId: id,
                    name: location.data.name,
                    companyName: location.data.company_name,
                    description: `'${JSON.stringify({
                      ...location.data.description,
                      ...(editorNeedSync
                        ? {
                            LOCATION_POINT_SHORT_INFO,
                            INSTANT_PANO_LOCATION_POINT_SHORT_INFO,
                            NORTH_DIRECTION,
                            USE_PARENT_GLTF: false,
                          }
                        : {}),
                      editorNeedSync: false,
                    })}'`,
                  };
                  await API.request(API.endpoints.UPDATE_LOCATION_FULL, data);
                  reload();
                  createToast(
                    'SUCCESS',
                    'Location description has been updated'
                  );
                } catch (error) {
                  console.error(error);
                  createToast('ERROR', error.toString());
                }
              }}
            />
          </GreyPage>
          {!isFloorPlanChecked ? (
            <>
              <ZipFileInput
                onSubmit={updateZipHandler}
                onModelParse={({ points, minimapPoints, furniture }) => {
                  setPoints([...points]);
                  setMinimapPoints(minimapPoints);
                  setFurnitureItems(furniture);
                  setModelParsed(x => x + 1);
                }}
                uploadingProgress={modelUploadingProgress}
                uploading={zipUploading}
                uploadingText={zipUploadingText}
                uploaded={location.data.description.MODEL_UPLOADED}
                setUploading={setZipUploading}
                resetUploadModel={() => setModelUploaded(false)}
                photoTourChecked={photoTourChecked}
                updateCubemapResolution={updateCubemapResolution}
                locationDescription={location.data.description}
                locationId={location.data.id}
                createToast={createToast}
                hasLocation={hasLocation}
                meshGenerationQueuePos={meshGenerationQueuePos}
              />
              {photoTourChecked && (
                <GreyPage>
                  <GTLFBuilderInput
                    initialValues={{
                      COMPANY_NAME: location.data.company_name,
                      ...location.data.description,
                    }}
                    instantPanoOnly={photoTourChecked}
                    onPointCountChange={count => {
                      const points =
                        location.data.description.LOCATION_POINT_SHORT_INFO;

                      const mipmapResolution = { width: 1024, height: 1024 };

                      if (points.length < count) {
                        for (let i = points.length; i < count; i++) {
                          const info = points[i];
                          points.push({
                            cam: { x: Math.random(), y: 0, z: 0 },
                            floor: 1,
                            markers: [],
                            name: 'Point №' + (i + 1),
                            x: 0,
                            y: 0,
                          });

                          location.data.description.LOCATION_POINT_SHORT_INFO =
                            points;
                        }
                      } else if (points.length > count) {
                        location.data.description.LOCATION_POINT_SHORT_INFO =
                          points.slice(0, count);
                      }

                      const minmapPoints =
                        location.data.description
                          .INSTANT_PANO_LOCATION_POINT_SHORT_INFO || [];

                      if (minmapPoints.length < count) {
                        for (let i = minmapPoints.length; i < count; i++) {
                          minmapPoints.push({
                            cam: { x: Math.random(), y: 0, z: 0 },
                            floor: 1,
                            markers: [],
                            name: 'Point №' + (i + 1),
                            x: 0,
                            y: 0,
                          });
                        }

                        location.data.description.INSTANT_PANO_LOCATION_POINT_SHORT_INFO =
                          minmapPoints;
                      } else if (minmapPoints.length > count) {
                        location.data.description.INSTANT_PANO_LOCATION_POINT_SHORT_INFO =
                          minmapPoints.slice(0, count);
                      }

                      // setPoints(location.data.description.LOCATION_POINT_SHORT_INFO);

                      // return location.data.description.LOCATION_POINT_SHORT_INFO
                    }}
                    onGltfUpdate={async points => {
                      console.log(location.data.description);
                      try {
                        const {
                          editorNeedSync,
                          INSTANT_PANO_LOCATION_POINT_SHORT_INFO,
                          LOCATION_POINT_SHORT_INFO,
                          NORTH_DIRECTION,
                        } = await API.getLocationDescription(id);
                        const data = {
                          locationId: id,
                          name: location.data.name,
                          companyName: location.data.company_name,
                          description: `'${JSON.stringify({
                            ...location.data.description,
                            ...(editorNeedSync
                              ? {
                                  LOCATION_POINT_SHORT_INFO,
                                  INSTANT_PANO_LOCATION_POINT_SHORT_INFO,
                                  NORTH_DIRECTION,
                                }
                              : {}),
                            editorNeedSync: false,
                          })}'`,
                        };
                        await API.request(
                          API.endpoints.UPDATE_LOCATION_FULL,
                          data
                        );
                        reload();
                        createToast(
                          'SUCCESS',
                          'Location description has been updated'
                        );
                      } catch (error) {
                        console.error(error);
                        createToast('ERROR', error.toString());
                      }
                    }}
                  />
                </GreyPage>
              )}
              <TileInput
                onSubmit={uploadTileHandler}
                onFileSelect={() => {
                  setTileAllowUploading(true);
                  setTileUploading(false);
                  setTilesUploaded(false);
                  setTileUploadError('');
                }}
                onTileSlicingCancel={cancelTileHandler}
                stats={tiles.stats}
                allowUploading={tileAllowUploading}
                uploading={tileUploading}
                tilesUploaded={tilesUploaded}
                resetTilesStatsProgress={resetTilesStatsProgress}
                error={tileUploadError}
                instantPanoOnly={photoTourChecked}
                uploaded={location.data.description.MODEL_UPLOADED}
                furnitureItems={furnitureItems}
                id={+id}
                location={location}
                hasLocation={hasLocation}
              />

              <GreyPage>
                <AudioFileInput
                  onSubmit={updateAudioHandler}
                  uploading={audioUploading}
                  audio={location.data.description.AUDIO}
                />
                <PreviewFileInput
                  onSubmit={updatePreviewHandler}
                  uploading={previewUploading}
                  image={location.data.description.PREVIEW_IMAGE}
                />
                <MapFileInput
                  onSubmit={updateMapHandler}
                  uploading={mapUploading}
                  floors={floors}
                  images={location.data.description.MAP_IMAGES}
                />

                <Form.Group as={Row}>
                  <Form.Label column sm={3}>
                    Export <InfoTooltipIcon tooltip="Export" />
                  </Form.Label>
                  <div className="col-sm-9">
                    <Button
                      onClick={() => setShowExport(!showExport)}
                      size="sm"
                    >
                      {showExport ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    </Button>
                  </div>
                </Form.Group>
              </GreyPage>

              {showExport && (
                <form
                  onSubmit={handleExportSubmit}
                  className="mt-4 mb-4 pt-4 pb-3 pl-4 pr-4 collapse show"
                  style={{ backgroundColor: 'rgb(244, 244, 244)' }}
                >
                  <div className="form-group row">
                    <label className="form-label col-form-label col-sm-3">
                      Player version
                    </label>
                    <div className="col-sm-9">
                      <select name="version">
                        {versions.map(version => (
                          <option key={version}>{version}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="form-label col-form-label col-sm-3">
                      Platform
                    </label>
                    <div className="col-sm-9">
                      <select name="platform">
                        <option>Windows</option>
                        <option>MacOS</option>
                      </select>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary"
                    style={{ marginBottom: '12px' }}
                  >
                    Export this and related locations
                  </button>
                </form>
              )}
            </>
          ) : (
            <>
              <GreyPage>
                <FrameZipInput
                  hasLocation={hasLocation}
                  uploaded={location.data.description.FRAMES_UPLOADED}
                  onSubmit={uploadFramesZipHandler}
                  onFileSelect={() => {
                    setFramesAllowUploading(true);
                    setFramesUploading(false);
                    setFramesUploaded(false);
                    setFramesUploadError('');
                  }}
                  allowUploading={framesAllowUploading}
                  uploading={framesUploading}
                  framesUploaded={framesUploaded}
                  error={framesUploadError}
                  id={+id}
                  location={location}
                />
              </GreyPage>
            </>
          )}
        </Container>
      )}
      <MarzipanoErrorsPopup locationId={id} />
    </div>
  );
};

LocationSettings.propTypes = {
  match: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  location: state.location,
  furnitureList: state.furnitureList,
  hotspotList: state.hotspotList,
  settings: state.settings,
  auth: state.auth,
  tiles: state.tiles,
  companies: state.companyList,
  companyData: state.company,
});

const mapDispatchToProps = {
  loadLocationDetails,
  resetLocationDetails,
  loadFurnitureList,
  loadHotspotList,
  updateSettings,
  createToast,
  loadTileStats,
  loadCompanies,
  loadLocationsOfCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationSettings);
