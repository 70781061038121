import axios from 'axios';
import * as XLSX from 'xlsx';
import { MagicTokenSingleton } from './MagicTokenSingleton';
import {
  DATA_PROP_ENUM,
  UNITS_PROP_NAME_ENUM,
} from 'containers/Explorer3d/EditorCSV/consts';

const base = process.env.REACT_APP_EXPLORER3D_URL;
const mediaUrl = process.env.REACT_APP_EXPLORER3D_MEDIA_URL;

export async function request({ method, url, body, params, onProgress }) {
  const u = new URL(base + url);
  const token = MagicTokenSingleton.I.getToken();
  let b = null;
  let headers = {};

  for (const [key, value] of Object.entries(params ?? {})) {
    u.searchParams.set(key, value.toString());
  }

  if (body instanceof FormData) {
    b = body;
  } else if (body) {
    headers = {
      accept: 'application/json',
      'content-type': 'application/json',
    };
    b = JSON.stringify(body);
  }

  const res = await axios(u.toString(), {
    method,
    data: b,
    onUploadProgress: p => {
      onProgress && onProgress(p.progress);
    },
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data;
}

export function createExplorer3d({ name, multilocationId }) {
  return request({
    method: 'POST',
    url: '/explorer3ds',
    body: {
      name,
      multilocationId,
    },
  });
}

export function uploadBuildingJson({ building, buildingId }) {
  const fd = new FormData();
  fd.append('file', building);

  return request({
    method: 'POST',
    url: `/buildings/${buildingId}/from/json/v1`,
    body: fd,
  });
}

export function uploadFloorPlan({ number, explorer3dId, file, onProgress }) {
  const fd = new FormData();
  // fd.append('floorId', floorId);
  fd.append('file', file);

  return request({
    method: 'POST',
    url: `/explorer3d/settings/${explorer3dId}/${number}/upload-floor-map`,
    body: fd,
    onProgress,
  });
}

export function deleteFloorPlan({ number, explorer3dId }) {
  return request({
    method: 'DELETE',
    url: `/explorer3d/settings/${explorer3dId}/${number}/floor-map`,
  });
}

export function createBuilding({
  explorer3dId,
  explorer3dIdTourId,
  address,
  name,
}) {
  return request({
    method: 'POST',
    url: `/buildings`,
    body: {
      explorer3dId,
      explorer3dIdTourId,
      address,
      name,
    },
  });
}

export function uploadLogo({ settingId, file }) {
  const fd = new FormData();
  fd.append('file', file);

  return request({
    method: 'POST',
    url: `/explorer3d/settings/${settingId}/upload`,
    body: fd,
  });
}

export function uploadAWSFiles({ settingId, file }) {
  const fd = new FormData();
  fd.append('file', file);

  return request({
    method: 'POST',
    url: `/explorer3d/settings/${settingId}/upload`,
    body: fd,
  });
}

export function updateExplorer3dSettings({
  settingId,
  mapLink,
  mapToken,
  centerPoint,
  engrainId,
}) {
  return request({
    method: 'PATCH',
    url: `/explorer3d/settings/${settingId}`,
    body: {
      centerPoint,
      mapLink,
      mapToken,
      engrainId,
    },
  });
}

export function uploadBgBuilding({ explorer3dId, file }) {
  const fd = new FormData();
  fd.append('file', file);

  return request({
    method: 'POST',
    url: `/explorer3d/settings/${explorer3dId}/upload-background`,
    body: fd,
  });
}

export function createBuildingInfo({
  buildingId,
  isChildernAvailable,
  isParkingAvailable,
  isAnimalsAvailable,
  hasHouseRules,
}) {
  return request({
    method: 'POST',
    url: `/info/buildings`,
    body: {
      id: buildingId,
      isChildernAvailable,
      isParkingAvailable,
      isAnimalsAvailable,
      hasHouseRules,
    },
  });
}

export function updateExplorer3dFull({ explorer3dId, settings, name }) {
  return request({
    method: 'PATCH',
    url: `/explorer3ds/update-full/${explorer3dId}`,
    body: {
      name,
      settings,
    },
  });
}

export function populateDatabase({
  explorer3dId,
  type,
  engrainAssetId,
  engrainEmbedId,
}) {
  return request({
    method: 'POST',
    url: `/explorer3ds/${explorer3dId}/populate`,
    body: {
      type,
      engrainAssetId,
      engrainEmbedId,
    },
  });
}

export function loadCsv({ explorer3dId, isFromRedactor }) {
  return request({
    method: 'POST',
    url: `/explorer3ds/${explorer3dId}/xls/generate`,
    body: {
      isFromRedactor,
    },
  });
}

export function uploadCsv({ file, explorer3dId }) {
  const fd = new FormData();
  fd.append('file', file);
  // fd.append('type', type);

  return request({
    method: 'POST',
    url: `/explorer3ds/${explorer3dId}/xls`,
    body: fd,
  });
}

export async function loadFile(url, name) {
  const res = await axios.get(mediaUrl + '/' + url, { responseType: 'blob' });

  const href = URL.createObjectURL(res.data);

  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export async function loadXLSXFile(id) {
  const url = await loadCsv({ explorer3dId: id, isFromRedactor: true });
  const res = await axios.get(mediaUrl + '/' + url, { responseType: 'blob' });

  const file = new File([res.data], 'example.txt', {
    type: 'text/plain',
  });

  return file;
}

export async function fetchAndParseFloorSVG(floorPlanUrl) {
  try {
    const url = mediaUrl + (floorPlanUrl ?? '');
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
    }
    const text = await response.text();
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(text, 'image/svg+xml');

    return svgDoc.documentElement;
  } catch (error) {
    console.error(error);

    return null;
  }
}

function createTemporarySVGContainer() {
  const container = document.createElement('div');
  container.style.position = 'absolute';
  container.style.top = '0';
  container.style.left = '0';
  container.style.width = '0';
  container.style.height = '0';
  container.style.overflow = 'hidden';
  document.body.appendChild(container);

  return container;
}

export function getSVGCoords(floorSVG, externalIds, floorNumber) {
  const tempContainer = createTemporarySVGContainer();

  tempContainer.appendChild(floorSVG);

  Object.keys(externalIds).forEach(id => {
    const element = floorSVG.querySelector(`[external-id="${id}"]`);

    if (element) {
      externalIds[id] = element.getBBox();
    } else {
      console.log(
        `Element with with External ID ${id} Floor Number ${floorNumber} not found.`
      );
    }
  });

  document.body.removeChild(tempContainer);

  return externalIds;
}

export async function createSVGData(floorPlans, normalizedData) {
  const floorSVGs = {};

  const templateCoordsData = normalizedData[DATA_PROP_ENUM.Units].reduce(
    (result, item) => {
      const floorNumber = item[UNITS_PROP_NAME_ENUM.FloorNumber];
      const externalId = item[UNITS_PROP_NAME_ENUM.ExternalId];

      if (result[floorNumber]) {
        result[floorNumber][externalId] = null;
      } else {
        result[floorNumber] = {};
        result[floorNumber][externalId] = null;
      }

      return result;
    },
    {}
  );

  await Promise.all(
    Object.entries(templateCoordsData).map(
      async ([floorNumber, externalIds]) => {
        const currentFloorSVG = await fetchAndParseFloorSVG(
          floorPlans[floorNumber]
        );

        if (currentFloorSVG) {
          floorSVGs[floorNumber] = currentFloorSVG;

          const SVGCoords = getSVGCoords(
            currentFloorSVG,
            externalIds,
            floorNumber
          );

          return SVGCoords;
        }
      }
    )
  );

  return {
    coordsData: templateCoordsData,
    floorSVGs,
  };
}

export async function uploadXLSXFile(id, jsonData) {
  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Convert JSON data to worksheets and append them to the workbook
  const worksheet1 = XLSX.utils.json_to_sheet(jsonData[DATA_PROP_ENUM.Units]);
  XLSX.utils.book_append_sheet(workbook, worksheet1, DATA_PROP_ENUM.Units);

  const worksheet2 = XLSX.utils.json_to_sheet(
    jsonData[DATA_PROP_ENUM.FloorPlanTypes]
  );
  XLSX.utils.book_append_sheet(
    workbook,
    worksheet2,
    DATA_PROP_ENUM.FloorPlanTypes
  );

  // Generate XLSX file and trigger a download
  // const newFile = XLSX.writeFile(workbook, 'test.xlsx');

  // Generate XLSX file in memory
  const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  // Create a Blob from the binary string
  const blob = new Blob([wbout], { type: 'application/octet-stream' });

  // Create FormData and append the Blob
  const formData = new FormData();
  formData.append('file', blob, 'data.xlsx');

  return request({
    method: 'POST',
    url: `/explorer3ds/${id}/xls`,
    body: formData,
  });
}

export function uploadAmenity({
  link,
  index,
  explorer3dId,
  name,
  file,
  fileName,
}) {
  const fd = new FormData();
  fd.append('file', file, fileName);
  fd.append('link', link);
  fd.append('index', index);
  fd.append('explorer3dId', explorer3dId);
  fd.append('name', name);
  // fd.append('fileName', fileName);

  return request({
    method: 'POST',
    url: `/amenity`,
    body: fd,
  });
}

export function deleteAmenity({ id }) {
  return request({
    method: 'DELETE',
    url: `/amenity/${id}`,
  });
}

export function bulkAmenitiesUpdate({ id, amenities }) {
  return request({
    method: 'POST',
    url: `/amenity/${id}/bulk`,
    body: amenities,
  });
}

export function updateAmenity({
  link,
  index,
  explorer3dId,
  name,
  file,
  fileName,
  id,
}) {
  const fd = new FormData();
  fd.append('file', file, fileName);
  fd.append('link', link);
  fd.append('index', index);
  fd.append('explorer3dId', explorer3dId);
  fd.append('name', name);
  // fd.append('fileName', fileName);

  return request({
    method: 'POST',
    url: `/amenity/${id}`,
    body: fd,
  });
}

export function updatePropertyDetails({ explorer3dId, details }) {
  return request({
    method: 'POST',
    url: `/property-data/${explorer3dId}`,
    body: {
      buildings: details,
    },
  });
}

export function generateSvgs({ id }) {
  return request({
    method: 'POST',
    url: `/explorer3ds/${id}/svg/generate`,
  });
}

export function recognizeSvg({ id }) {
  return request({
    method: 'POST',
    url: `/explorer3ds/${id}/svg/recognize`,
  });
}
