import { combineReducers } from 'redux';

import { reducer as formReducer } from 'redux-form';
import CompanyListReducer from './CompanyListReducer';
import LocationReducer from './LocationReducer';
import FurnitureReducer from './FurnitureReducer';
import HotspotReducer from './HotspotReducer';
import LocationHotspotReducer from './LocationHotspotReducer';
import ToastsReducer from './ToastsReducer';
import SettingsReducer from './SettingsReducer';
import AuthReducer from './AuthReducer';
import TileReducer from './TileReducer';
import CompanyReducer from './CompanyReducer';
import SearchReducer from './SearchReducer';
import HideEmptyFoldersReducer from './HideEmptyFoldersReducer';
import PopupReducer from './PopupReducer';
import ContextMenuReducer from './ContextMenuReducer';
import Explorer3dReducer from './Explorer3dReducer';
import EditorCSVReducer from './EditorCSVReducer';

export default combineReducers({
  form: formReducer,
  companyList: CompanyListReducer,
  location: LocationReducer,
  furnitureList: FurnitureReducer,
  hotspotList: HotspotReducer,
  explorer3d: Explorer3dReducer,
  locationHotspotList: LocationHotspotReducer,
  toasts: ToastsReducer,
  settings: SettingsReducer,
  auth: AuthReducer,
  tiles: TileReducer,
  company: CompanyReducer,
  searchName: SearchReducer,
  hideEmptyFolders: HideEmptyFoldersReducer,
  popup: PopupReducer,
  contextMenu: ContextMenuReducer,
  editorCSV: EditorCSVReducer,
});
