import React, { useContext, useState } from 'react';
import { Field } from 'redux-form';
import {
  AccordionContext,
  Button,
  Container,
  Row,
  useAccordionToggle,
} from 'react-bootstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

import renderField from '../../shared/components/form/Field';

import MapSettingsPopup from './MapSettingsPopup';
import { useDispatch, useSelector } from 'react-redux';
import { updateSigntmapSettigns } from '../../redux/actions';

const CustomToggle = ({ eventKey }) => {
  const toggle = useAccordionToggle(eventKey);
  const currentEventKey = useContext(AccordionContext);

  return (
    <div
      className={
        currentEventKey === eventKey
          ? 'explorer3d-collapse explorer3d-collapse-open'
          : 'explorer3d-collapse'
      }
      onClick={toggle}
    >
      <span>Map settings</span>
      <ChevronDownIcon />
    </div>
  );
};

function Neighborhood() {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const dispatch = useDispatch();

  const settings = useSelector(state => state.explorer3d.current.settings);

  const hidePopup = () => {
    setIsOpenPopup(false);
  };

  const openPopup = () => {
    setIsOpenPopup(true);
  };
  return (
    <Container className="mt-1">
      <h2 className="explorer3d-item-title">Neighborhood</h2>
      <Field
        name="settings['centerPoint']"
        type="text"
        label="Property location"
        component={renderField}
        initialValue={settings.centerPoint ?? ''}
        onUpdateStore={val => {
          dispatch(
            updateSigntmapSettigns({
              ...settings,
              centerPoint: val,
            })
          );
        }}
      />
      <Field
        name="settings['mapNorth']"
        type="number"
        min={0}
        tooltip={
          <>
            <div>Degrees: 0-360</div>
            <div>Direction: Clockwise from top</div>
            <div>Default: 0 (Top)</div>
          </>
        }
        label="Map north"
        component={renderField}
        initialValue={settings.mapNorth ?? ''}
        onUpdateStore={val => {
          dispatch(
            updateSigntmapSettigns({
              ...settings,
              mapNorth: val,
            })
          );
        }}
      />
      <Field
        name="settings['fillOpacity']"
        type="text"
        label="Highlight opacity"
        component={renderField}
        tooltip={
          <>
            <div>Property highlight opacity</div>
            <div>Range: 0-1</div>
            <div>Default: 0.5</div>
          </>
        }
        initialValue={settings.fillOpacity ?? ''}
        onUpdateStore={val => {
          dispatch(
            updateSigntmapSettigns({
              ...settings,
              fillOpacity: val,
            })
          );
        }}
      />
      <Field
        name="settings['strokeWidth']"
        type="text"
        label="Highlight width"
        component={renderField}
        tooltip={
          <>
            <div>Property highlight width</div>
            <div>Default: 2</div>
          </>
        }
        initialValue={settings.strokeWidth ?? ''}
        onUpdateStore={val => {
          dispatch(
            updateSigntmapSettigns({
              ...settings,
              strokeWidth: val,
            })
          );
        }}
      />
      <Row>
        <Container className="p-3">
          <Button onClick={openPopup}>Map setup</Button>
        </Container>
      </Row>
      {isOpenPopup && (
        <MapSettingsPopup closeEditor={hidePopup} opened={isOpenPopup} />
      )}
    </Container>
  );
}

export default Neighborhood;
