import React, { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import renderField from './Field';
import { Field } from 'redux-form';

const hexColorPattern = /^#([0-9A-F]{3}|[0-9A-F]{6})$/i;
const defaultColor = '#D9EAFF';

const ColorPicker = ({
  fieldName,
  label,
  onChangeColorPicker,
  initialValue,
}) => {
  const settingsColor = useSelector(
    state => state.explorer3d.current.settings[fieldName]
  );
  const [currentColor, setCurrentColor] = useState(settingsColor);

  const handleTextValueChange = useCallback((_, newColorValue) => {
    if (hexColorPattern.test(newColorValue)) {
      setCurrentColor(newColorValue);
    }
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <Field
        name={`settings[${fieldName}]`}
        type="text"
        label={label}
        component={renderField}
        onChange={handleTextValueChange}
        initialValue={initialValue ?? ''}
      />
      <div className="color-picker">
        <input
          type="color"
          onChange={e => {
            setCurrentColor(e.target.value);
            onChangeColorPicker(e.target.value);
          }}
          value={currentColor || defaultColor}
        />
        <div
          className="color-picker-container"
          style={{
            backgroundColor: hexColorPattern.test(currentColor)
              ? currentColor
              : defaultColor,
          }}
        />
      </div>
    </div>
  );
};

export default memo(ColorPicker);
