import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Form } from 'react-bootstrap';
// import {
//   loadExplorer3dById,
//   createToast,
//   showPopup,
//   hidePopup,
// } from 'redux/actions';
// import {
//   uploadFloorPlan,
//   uploadBuildingJson,
//   uploadBgBuilding,
//   createBuildingInfo,
//   createBuilding,
// } from 'shared/utils/Explorer3dApi';
import Explorer3dSettings from './Explorer3dSettings';
import IslandContainer from './IslandContainer';
import Explorer3dHeader from './Explorer3dHeader';
import { getFormValues, reduxForm } from 'redux-form';
import scrollToInvalid from 'shared/utils/scrollToInvalid';
import { TAB_ENUM } from './types';
import FloorTab from './FloorsTab';
import Amenities from './Amenity/Amenities';
import Database from './Database';
import PropertyEditor from './PropertyEditor';
import BaseSettings from './BaseSettings';
import ContentEditor from './ContentEditor/ContentEditor';

function Explorer3d({ handleSubmit }) {
  // const dispatch = useDispatch();
  const explorer3d = useSelector(state => state.explorer3d?.current);
  const explorer3dId = explorer3d.settings.id;

  const currentTab = useSelector(state => state.explorer3d.activeTab);

  const formValues = useSelector(state => getFormValues('explorer3dForm')(state));

  const isFullScreen =
    currentTab === TAB_ENUM.AMENITIES ||
    currentTab === TAB_ENUM.FLOORS ||
    currentTab === TAB_ENUM.CYCLES ||
    currentTab === TAB_ENUM.MARKERS;

  // function uploadJsonHandler(buildingId) {
  //   return async files => {
  //     await uploadBuildingJson({ buildingId, building: files[0] });
  //     dispatch(loadExplorer3dById(explorer3d.id));
  //     dispatch(createToast('SUCCESS', 'JSON loaded'));
  //   };
  // }

  // function uploadImagesHandler(buildingId) {
  //   return async files => {
  //     const building = explorer3d.buildings.find(b => b.id === buildingId);

  //     for (const file of files) {
  //       const num = +file.name.split('.')[0].slice(5);

  //       const floor = building.floors.find(f => f.number === num);
  //       await uploadFloorPlan({ file, floorId: floor.id });
  //     }

  //     dispatch(loadExplorer3dById(explorer3d.id));
  //   };
  // }

  // function uploadBgHandler(id) {
  //   return async files => {
  //     if (!explorer3d.buildings.find(b => b.id === id).info) {
  //       await createBuildingInfo({ buildingId: id });
  //     }
  //     await uploadBgBuilding({ buildingId: id, file: Array.from(files)[0] });
  //     dispatch(loadExplorer3dById(explorer3d.id));
  //   };
  // }

  function submitHandler(e) {
    if (e) e.preventDefault();

    handleSubmit(e);
  }

  // async function addBuildingHandler() {
  //   dispatch(
  //     showPopup({
  //       onSubmit: async data => {
  //         await createBuilding({
  //           ...data,
  //           explorer3dId: explorer3d.id,
  //           explorer3dTourId: +data.explorer3dTourId,
  //         });
  //         dispatch(hidePopup());
  //         dispatch(loadExplorer3dById(explorer3d.id));
  //       },
  //       label: {
  //         text: 'Create explorer3d',
  //         color: 'black',
  //       },
  //       submitButton: {
  //         text: 'Create',
  //         variant: 'success',
  //       },
  //       formData: [
  //         {
  //           type: 'input',
  //           name: 'name',
  //           props: {
  //             placeholder: 'Building name',
  //             type: 'text',
  //             // onChangeValidate: true,
  //           },
  //         },
  //         {
  //           type: 'input',
  //           name: 'address',
  //           props: {
  //             placeholder: 'Building address',
  //             type: 'text',
  //             // onChangeValidate: true,
  //           },
  //         },
  //         {
  //           type: 'input',
  //           name: 'explorer3dTourId',
  //           props: {
  //             placeholder: 'Explorer3d tour id',
  //             type: 'text',
  //             // onChangeValidate: true,
  //           },
  //         },
  //       ],
  //     })
  //   );
  // }

  if (!explorer3d) return null;

  function getTab() {
    if (currentTab === TAB_ENUM.SETTINGS) {
      return (
        <>
          <IslandContainer isFullScreen={isFullScreen}>
            <BaseSettings />
          </IslandContainer>

          <IslandContainer isFullScreen={isFullScreen}>
            <Database />
          </IslandContainer>

          <IslandContainer isFullScreen={isFullScreen} noPadding={true}>
            <PropertyEditor />
          </IslandContainer>

          <IslandContainer isFullScreen={isFullScreen}>
            <Explorer3dSettings explorer3d={explorer3d} />
          </IslandContainer>
        </>
      );
    } else if (currentTab === TAB_ENUM.FLOORS) {
      return <FloorTab isFullScreen={isFullScreen} />;
    } else if (currentTab === TAB_ENUM.AMENITIES) {
      return <Amenities isFullScreen={isFullScreen} />;
    } else if (currentTab === TAB_ENUM.CONTENT_EDITOR) {
      return <ContentEditor isFullScreen={isFullScreen} />;
    } else if (currentTab === TAB_ENUM.CYCLES) {
      return (
        <iframe
          className="w-100 h-100"
          frameBorder={0}
          src={`${process.env.REACT_APP_WIDGET_PLAYER_URL}/cycles-editor?locationId=${formValues.settings.overviewLocation}&explorer3dId=${explorer3dId}`}
        ></iframe>
      );
    } else if (currentTab === TAB_ENUM.MARKERS) {
      return (
        <iframe
          className="w-100 h-100"
          frameBorder={0}
          src={`${process.env.REACT_APP_WIDGET_PLAYER_URL}/markers-editor?locationId=${formValues.settings.overviewLocation}&explorer3dId=${explorer3dId}`}
        ></iframe>
      );
    }
  }

  return (
    <Container
      fluid
      className={`bg-grey w-100 explorer3d ${
        isFullScreen ? 'no-padding no-margin h-100' : 'p-4'
      }`}
      style={{ flex: 1 }}
    >
      <Form
        onSubmit={submitHandler}
        className={`w-100 h-100 d-flex flex-column ${!isFullScreen && 'explorer3d-gap'} explorer3d-form`}
      >
        <Explorer3dHeader explorer3d={explorer3d} isFullScreen={isFullScreen} />
        {getTab()}
      </Form>
    </Container>
  );
}

export default reduxForm({
  form: 'explorer3dForm',
  onSubmitFail: errors => scrollToInvalid(errors),
  // validate,
  enableReinitialize: true,
})(Explorer3d);
