import * as actionTypes from './types';

const explorer3dBasePath = process.env.REACT_APP_EXPLORER3D_URL;

export const loadExplorer3ds = () => {
  return dispatch => {
    fetch(`${explorer3dBasePath}/explorer3ds?offset=0&limit=10000000`)
      .then(response => response.json())
      .then(result => {
        dispatch({
          type: actionTypes.LOAD_EXPLORER3DS,
          payload: result.list,
        });
      });
  };
};

export const loadExplorer3dsByMultilocationId = id => {
  return dispatch => {
    fetch(`${explorer3dBasePath}/explorer3ds/multilocation/${id}`)
      .then(response => response.json())
      .then(result => {
        dispatch({
          type: actionTypes.LOAD_EXPLORER3DS,
          payload: result.list,
        });
      });
  };
};

export function setCurrentExplorer3d(s) {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_CURRENT_EXPLORER3D,
      payload: s,
    });
  };
}

export const loadExplorer3dById = id => {
  return dispatch => {
    fetch(`${explorer3dBasePath}/explorer3ds/${id}`)
      .then(response => response.json())
      .then(result => {
        dispatch({
          type: actionTypes.SET_CURRENT_EXPLORER3D,
          payload: result,
        });
      });
  };
};

export const loadUnits = id => {
  return dispatch => {
    fetch(`${explorer3dBasePath}/units/${id}`)
      .then(response => response.json())
      .then(result => {
        dispatch({
          type: actionTypes.LOAD_UNITS_LIST,
          payload: result,
        });
      });
  };
};

export const loadUnitTypes = id => {
  return dispatch => {
    fetch(`${explorer3dBasePath}/unit-types/${id}`)
      .then(response => response.json())
      .then(result => {
        dispatch({
          type: actionTypes.LOAD_UNITTYPES_LIST,
          payload: result,
        });
      });
  };
};

export const loadAwsUrls = (locationId, entityId, contentType, entityType) => {
  if (entityType === 'floorplans') {
    entityId = entityId.id;
  } else {
    entityId = entityId?.id;
  }

  return dispatch => {
    fetch(
      `${explorer3dBasePath}/aws/get-links?entityId=${entityId}&contentType=${contentType}`
    )
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: actionTypes.LOAD_AWS_URLS,
          payload: data,
        });
      });
  };
};

export const deleteFileFromAws = (
  url,
  locationId,
  entityId,
  contentType,
  entityType
) => {
  return dispatch => {
    fetch(`${explorer3dBasePath}/aws/delete-file`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fileUrl: url,
        entityType: entityType,
      }),
    })
      .then(async response => await response.json())
      .then(async result => {
        await dispatch({
          type: actionTypes.DELETE_AWS_FILE,
          payload: result?.message,
        });
        await dispatch({
          type: actionTypes.LOAD_AWS_URLS,
          payload: result?.urls,
        });
      });
  };
};

export const uploadFilesToAws = (files, bucketName, folderName) => {
  return dispatch => {
    const formData = new FormData();

    Array.from(files).forEach(file => {
      formData.append('files', file); // 'files' should match the name in your controller
    });

    formData.append('bucketName', bucketName);
    formData.append('folderName', folderName);

    fetch(`${explorer3dBasePath}/aws/upload-files`, {
      method: 'POST',
      body: formData,
    })
      .then(async response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return await response.json();
      })
      .then(async result => {
        await dispatch({
          type: actionTypes.UPLOAD_AWS_FILES,
          payload: result?.message,
        });
        dispatch({
          type: actionTypes.LOAD_AWS_URLS,
          payload: result?.urls,
        });
      })
      .catch(error => {
        console.error('Error uploading files:', error);
        dispatch({
          type: actionTypes.UPLOAD_AWS_FILES_ERROR,
          payload: error.message,
        });
      });
  };
};

export function updateSigntmapSettigns(settings) {
  return dispatch => {
    dispatch({
      type: actionTypes.UPDATE_EXPLORER3D_SETTINGS,
      payload: settings,
    });
  };
}

export function setActiveTab(tab) {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_ACTIVE_TAB,
      payload: { tab },
    });
  };
}

export function updateBuildingInfo(id, info) {
  return dispatch => {
    dispatch({
      type: actionTypes.UPDATE_BUILDING_INFO,
      payload: { id, info },
    });
  };
}

export function setAmenities(amenities) {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_AMENITIES,
      payload: amenities,
    });
  };
}

export function setPropertyDetails(details) {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_PROPERTY_DETAILS,
      payload: details,
    });
  };
}
