import React, { useState } from 'react';
import AwsPopup from './AwsPopup';

function AwsUrlsButtons({
  handleClick,
  handleDelete,
  isOpenAlerts,
  setIsOpenAlerts,
  index,
  item,
}) {
  const [showPopup, setShowPopup] = useState(false);

  const hidePopup = () => {
    setShowPopup(false);
  };

  const openPopup = () => {
    setShowPopup(true);
  };

  return (
    <>
      <AwsPopup show={showPopup} onClose={hidePopup} item={item} />
      {!isOpenAlerts?.[index] ? (
        <>
          <button
            className="btn btn-download mt-2"
            onClick={(e) => {
              e.preventDefault();
              handleClick(item?.link, item?.fileName)
            }}
          >
            <img src={`${process.env.PUBLIC_URL}/img/download.svg`} />
          </button>
          <button
            className="btn btn-danger mt-2"
            onClick={(e) => {
              e.preventDefault();
              setIsOpenAlerts(
                isOpenAlerts.map((_, i) => {
                  if (i === index) {
                    return true;
                  }
                  return false;
                })
              );
            }}
          >
            <img src={`${process.env.PUBLIC_URL}/img/remove.svg`} />
          </button>
          <button className="btn btn-view mt-2" onClick={(e) => {
              e.preventDefault();
              openPopup();
            }}>
            <img src={`${process.env.PUBLIC_URL}/img/view.svg`} />
          </button>
        </>
      ) : (
        <div className="delete-alert">
          Do you want to delete this file?
          <button
            onClick={(e) => {
              e.preventDefault();
              setIsOpenAlerts(isOpenAlerts.map(_ => false));
            }}
          >
            Cancel
          </button>
          <button
            className="delete-alert-danger-button"
            onClick={(e) => {
              e.preventDefault();
              handleDelete(item?.link);
            }}
          >
            Delete
          </button>
        </div>
      )}
    </>
  );
}

export default AwsUrlsButtons;
