import * as XLSX from 'xlsx';
import * as actionTypes from './types';
import {
  loadXLSXFile,
  createSVGData,
  generateSvgs,
  recognizeSvg,
} from 'shared/utils/Explorer3dApi';
import { normalizeDataCSV } from 'containers/Explorer3d/EditorCSV/utils';
export * from './explorer3d';

const endpoint = `${process.env.REACT_APP_BACKEND_URL}/api`;
const endpointTilegen = `${process.env.REACT_APP_TILEGEN_URL}`;

export const loadLocationsOfCompany = company => {
  return dispatch => {
    fetch(
      `${endpoint}/getCompanyData?companyName=${encodeURIComponent(company)}`
    )
      .then(response => response.json())
      .then(result => {
        dispatch({
          type: actionTypes.LOAD_LOCATIONS_OF_COMPANY,
          payload: result.result,
        });
      });
  };
};

export const loadCompanies = () => {
  return dispatch => {
    fetch(`${endpoint}/getAllCompanies`)
      .then(response => response.json())
      .then(result => {
        dispatch({
          type: actionTypes.LOAD_COMPANIES,
          payload: { companies: result.result },
        });
      });
  };
};

export const loadCompaniesBySearch = name => {
  return dispatch => {
    fetch(`${endpoint}/locations/search?name=${encodeURIComponent(name)}`)
      .then(response => response.json())
      .then(result => {
        dispatch({
          type: actionTypes.LOAD_COMPANIES,
          payload: { companies: result.result },
        });
      });
  };
};

export const loadFurnitureList = () => {
  return dispatch => {
    fetch(`${endpoint}/getAllFurniture`)
      .then(response => response.json())
      .then(response => {
        dispatch({
          type: actionTypes.LOAD_FURNITURE,
          payload: { furniture: response.result },
        });
      });
  };
};

export const loadHotspotList = () => {
  return dispatch => {
    fetch(`${endpoint}/getAllHotspots`)
      .then(response => response.json())
      .then(response => {
        if (response.result.length) {
          dispatch({
            type: actionTypes.LOAD_HOTSPOTS,
            payload: { hotspot: response.result },
          });
        }
      });
  };
};

export const loadLocationHotspotList = locationId => {
  return dispatch => {
    fetch(`${endpoint}/location/hotspots/?locationId=${locationId}`)
      .then(response => response.json())
      .then(response => {
        dispatch({
          type: actionTypes.LOAD_LOCATION_HOTSPOTS,
          payload: { hotspot: response.result },
        });
      });
  };
};

export const loadLocationDetails = id => {
  return dispatch => {
    fetch(`${endpoint}/getLocationById?locationId=${id}&hash=${Date.now()}`)
      .then(response => response.json())
      .then(result => {
        // migration: change point format
        const points =
          result.result[0].description.LOCATION_POINT_SHORT_INFO || [];
        const fixedPoints = points.map(point => {
          if (typeof point === 'string') {
            return {
              name: point,
              x: 0,
              y: 0,
            };
          }

          return point;
        });

        // migration: set autorotation

        result.result[0].description.AUTOROTATION = true;
        result.result[0].description.AUTOFOLLOW = true;

        dispatch({
          type: actionTypes.LOAD_LOCATION,
          payload: {
            location: {
              data: {
                ...result.result[0],
                description: {
                  ...result.result[0].description,
                  LOCATION_POINT_SHORT_INFO: fixedPoints,
                },
              },
              loaded: true,
            },
          },
        });
      })
      .catch(e => {
        dispatch({
          type: actionTypes.LOAD_LOCATION,
          payload: {
            location: {
              data: null,
              loaded: true,
              error: e,
            },
          },
        });
      });
  };
};

export const loadTileStats = id => {
  return dispatch => {
    fetch(`${endpointTilegen}/jobs/stats?location=${id}`)
      .then(response => response.json())
      .then(result => {
        dispatch({
          type: actionTypes.LOAD_TILE_STATS,
          payload: { stats: result },
        });
      });
  };
};

export const resetCompanies = () => {
  return {
    type: actionTypes.RESET_LOCATIONS,
  };
};

export const resetCompanyLocations = () => {
  return {
    type: actionTypes.RESET_COMPANY_LOCATIONS,
  };
};

export const resetLocationDetails = () => {
  return {
    type: actionTypes.RESET_LOCATION,
  };
};

export const updateSettings = settings => {
  const savedSettings = localStorage.getItem('settings');
  const jsonSettings = savedSettings ? JSON.parse(savedSettings) : {};
  localStorage.setItem(
    'settings',
    JSON.stringify({
      ...jsonSettings,
      ...settings,
      showPlayer: undefined, // showPlayer is deprecated
    })
  );

  return {
    type: actionTypes.UPDATE_SETTINGS,
    payload: { settings },
  };
};

export const authenticate = auth => {
  return dispatch => {
    dispatch({
      type: actionTypes.AUTH_LOGIN,
      payload: { auth },
    });
  };
};

export const deauthenticate = () => {
  console.log('action/deauth');

  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const createToast = (type, text, heading = undefined) => {
  return {
    type: actionTypes.CREATE_TOAST,
    payload: { text, type, heading },
  };
};

export const deleteToast = id => {
  return {
    type: actionTypes.DELETE_TOAST,
    payload: id,
  };
};

export const setSearchName = name => {
  return dispatch =>
    dispatch({
      type: actionTypes.SET_SEARCH_NAME,
      name,
    });
};

export const setHideEmptyFolders = state => {
  return dispatch =>
    dispatch({
      type: actionTypes.SET_HIDE_EMPTY_FOLDERS,
      checked: state,
    });
};

export const showPopup = payload => {
  return dispatch =>
    dispatch({
      type: actionTypes.SHOW_POPUP,
      payload,
    });
};

export const hidePopup = () => {
  return dispatch =>
    dispatch({
      type: actionTypes.HIDE_POPUP,
    });
};

export const showContextMenu = payload => {
  return dispatch =>
    dispatch({
      type: actionTypes.SHOW_CONTEXT_MENU,
      payload,
    });
};

export const hideContextMenu = () => {
  return dispatch =>
    dispatch({
      type: actionTypes.HIDE_CONTEXT_MENU,
    });
};

export const loadDataCSV = (explorer3dId, isNeedRecognizeSvg) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.SET_DATA_CSV,
      payload: null,
    });
    dispatch({
      type: actionTypes.SET_IS_DATA_CSV_LOADING,
      isDataCSVLoading: true,
    });

    try {
      if (isNeedRecognizeSvg) {
        await recognizeSvg({ id: explorer3dId });
      }

      const settings = await generateSvgs({ id: explorer3dId });
      const file = await loadXLSXFile(explorer3dId);
      const reader = new FileReader();

      reader.onload = async e => {
        const arrayBuffer = e.target.result;
        const binaryStr = new Uint8Array(arrayBuffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        );
        const workbook = XLSX.read(binaryStr, { type: 'binary' });

        // Convert all sheets to JSON
        const sheetsData = {};
        workbook.SheetNames.forEach(sheetName => {
          const worksheet = workbook.Sheets[sheetName];
          sheetsData[sheetName] = XLSX.utils.sheet_to_json(worksheet, {
            raw: false, // Convert dates and numbers to text
            defval: '', // Default value for empty cells
          });
        });

        const normalizedData = normalizeDataCSV(sheetsData);
        const dataSVG = await createSVGData(
          settings.floorPlans,
          normalizedData
        );

        dispatch({
          type: actionTypes.SET_PROPERTY_SVG_COORDINATES,
          propertySVGCoordinates: dataSVG.coordsData,
        });
        dispatch({
          type: actionTypes.SET_FLOOR_SVGS,
          floorSVGs: dataSVG.floorSVGs,
        });
        dispatch({
          type: actionTypes.SET_DATA_CSV,
          payload: normalizedData,
        });
        dispatch({
          type: actionTypes.SET_IS_DATA_CSV_LOADING,
          isDataCSVLoading: false,
        });
      };

      reader.readAsArrayBuffer(file);
    } catch (error) {
      dispatch({
        type: actionTypes.SET_IS_DATA_CSV_LOADING,
        isDataCSVLoading: false,
      });
      dispatch(createToast('ERROR', 'Error on loading XLSX file'));
    }
  };
};

export const getRecognizedSvgData = explorer3dId => {
  return async dispatch => {
    dispatch({
      type: actionTypes.SET_DATA_CSV,
      payload: null,
    });
    dispatch({
      type: actionTypes.SET_IS_DATA_CSV_LOADING,
      isDataCSVLoading: true,
    });

    try {
      const result = await recognizeSvg({ id: explorer3dId });

      return result;
    } catch (error) {
      console.log('Error on getting recognized SVG data', error);
      dispatch({
        type: actionTypes.SET_IS_DATA_CSV_LOADING,
        isDataCSVLoading: false,
      });
      dispatch(createToast('ERROR', 'Error on getting recognized SVG data'));
    }
  };
};
