import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setPropertyDetails } from 'redux/actions';
import { cloneDeep } from 'lodash';
import DeleteIcon from 'mdi-react/DeleteIcon';
import IslandContainer from './IslandContainer';

function Building({ building }) {
  const dispatch = useDispatch();
  const explorer3d = useSelector(state => state.explorer3d.current);

  const [count, setCount] = useState(1);
  const [isTrashIconHovered, setIsTrashIconHovered] = useState(false);

  function addSectionHandler() {
    const newDetails = cloneDeep(explorer3d.propertyDetails);

    const b = newDetails.find(b => b.internalId === building.internalId);
    // const allSections = newDetails.map(b => b.sections).flat();

    for (let i = 0; i < count; i++) {
      b.sections.push({
        internalId: `${b.internalId}S${b.sections.length + i + 1}`,
        name: 'My section',
        floorFrom: 0,
        floorTo: 0,
        floors: [],
      });
    }

    dispatch(setPropertyDetails(newDetails));
  }

  function changeSectionField(field, id) {
    return e => {
      const newDetails = cloneDeep(explorer3d.propertyDetails);

      const b = newDetails.find(b => b.internalId === building.internalId);
      const s = b.sections.find(s => s.internalId === id);

      s[field] = e.target.value;

      dispatch(setPropertyDetails(newDetails));
    };
  }

  function changeBuildingField(field, val) {
    return e => {
      const newDetails = cloneDeep(explorer3d.propertyDetails);

      const b = newDetails.find(b => b.internalId === building.internalId);

      b[field] = val ?? e.target.value;

      dispatch(setPropertyDetails(newDetails));
    };
  }

  return (
    <Row
      style={{ backgroundColor: isTrashIconHovered ? '#F4F4F4' : '' }}
      className="property-building"
    >
      <Col sm={3}>Building {building.internalId.slice(1)}</Col>
      <Col sm={9}>
        <div className="property-wrapper">
          <div className="property-row-wrapper">
            <div className="property-row top-rounded">
              <span className="property-row-title">{building.internalId}</span>
              <input
                className="input w-100"
                value={building.name}
                onChange={changeBuildingField('name')}
              />
            </div>
            {building.sections
              .sort(
                (a, b) =>
                  +a.internalId.split('S')[1] - +b.internalId.split('S')[1]
              )
              .map(s => (
                <div className="property-row" key={s.id || s.internalId}>
                  <Container className="p-0">
                    <Row sm={2}>
                      <Col className="d-flex align-items-center">
                        <span className="property-row-title">
                          {s.internalId}
                        </span>
                        <input
                          className="input flex-grow-1"
                          value={s.name}
                          onChange={changeSectionField('name', s.internalId)}
                        />
                      </Col>
                      <Col className="property-floor-item">
                        <div className="property-floor-number">
                          <span className="property-floor-title">
                            Floor from
                          </span>
                          <input
                            className="input w-20"
                            type="number"
                            value={s.floorFrom}
                            onChange={changeSectionField(
                              'floorFrom',
                              s.internalId
                            )}
                          />
                        </div>
                        <div className="property-floor-number">
                          <span className="property-floor-title">Floor to</span>
                          <input
                            className="input w-20"
                            type="number"
                            value={s.floorTo}
                            onChange={changeSectionField(
                              'floorTo',
                              s.internalId
                            )}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              ))}

            <div className="property-row bottom-rounded">
              <Button
                className="btn-secondary"
                variant="secondary"
                onClick={addSectionHandler}
              >
                Add {count} section
              </Button>
            </div>
          </div>
          <div
            className="trash-icon-container"
            onPointerEnter={() => setIsTrashIconHovered(true)}
            onPointerOut={() => setIsTrashIconHovered(false)}
            onClick={changeBuildingField('status', 'DELETED')}
          >
            <DeleteIcon
              color={isTrashIconHovered ? '#DC3545' : '#999C9F'}
              className="trash-icon"
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}

function PropertyEditor() {
  const dispatch = useDispatch();

  const explorer3d = useSelector(state => state.explorer3d.current);

  const [count, setCount] = useState(1);

  function addBuildingHandler() {
    let newDetails = cloneDeep(explorer3d.propertyDetails);

    const len = newDetails.length;

    for (let i = 0; i < count; i++) {
      const deletedBuilding = newDetails.find(d => d.status === 'DELETED');
      if (deletedBuilding) {
        newDetails = newDetails.filter(d => d !== deletedBuilding);
        newDetails.push({
          internalId: deletedBuilding.internalId,
          name: 'My building',
          sections: [],
          status: 'ACTIVE',
        });
      } else {
        newDetails.push({
          internalId: `B${len + i + 1}`,
          name: 'My building',
          status: 'ACTIVE',
          sections: [],
        });
      }
    }

    dispatch(setPropertyDetails(newDetails));
  }

  return (
    <div className="rounded bg-white p-2 container property-editor">
      <IslandContainer>
        <h2 className="explorer3d-item-title">Property Structure</h2>
        {cloneDeep(explorer3d.propertyDetails)
          ?.filter(b => b.status !== 'DELETED')
          .sort((a, b) => +a.internalId.slice(1) - +b.internalId.slice(1))
          .map((b, index) => {
            return (
              <Building key={b.id || b.internalId} building={b} index={index} />
            );
          })}
        <Row className="d-flex align-items-center pt-2">
          <Col sm={3}>Add building</Col>
          <Col sm={9}>
            <Button variant="outline-primary" onClick={addBuildingHandler}>
              Add {count} building
            </Button>
          </Col>
        </Row>
      </IslandContainer>
    </div>
  );
}

export default PropertyEditor;
