import React from 'react';
import { getCellProperty } from '@silevis/reactgrid';
import { FaChevronDown } from 'react-icons/fa';
import {
  DATA_PROP_ENUM,
  FLOOR_PLAN_SORTABLE_PROPS,
  UNITS_SORTABLE_PROPS,
} from './consts';

export class CustomHeaderCell {
  constructor(handleCellClick, dataPropName) {
    this.handleCellClick = handleCellClick;
    this.dataPropName = dataPropName;
  }

  getCompatibleCell(uncertainCell) {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    const value = parseFloat(text);

    return { ...uncertainCell, text, value };
  }

  render(cell) {
    let sortableProps = UNITS_SORTABLE_PROPS;

    if (this.dataPropName === DATA_PROP_ENUM.FloorPlanTypes) {
      sortableProps = FLOOR_PLAN_SORTABLE_PROPS;
    }

    const isSortableColumn = sortableProps.includes(cell.text);

    const handleClick = () => {
      if (isSortableColumn) {
        this.handleCellClick(cell.text);
      }
    };

    return (
      <div
        className={cell.className}
        onCopy={e => e.stopPropagation()}
        onCut={e => e.stopPropagation()}
        onPaste={e => e.stopPropagation()}
        onPointerDown={e => e.stopPropagation()}
        onKeyDown={e => e.stopPropagation()}
        onClick={handleClick}
        style={isSortableColumn ? { cursor: 'pointer' } : {}}
      >
        <span>{cell.text}</span>
        {isSortableColumn && (
          <span className="header-cell-icon">
            <FaChevronDown />
          </span>
        )}
      </div>
    );
  }
}
