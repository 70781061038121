export const CELL_TYPE_ENUM = {
  HEADER: 'header',
  TEXT: 'text',
  NUMBER: 'number',
  CHECKBOX: 'checkbox',
  DATE: 'date',
  DROPDOWN: 'dropdown',
  EMAIL: 'email',
  TIME: 'time',
};

export const SORT_BY = {
  ASCEND: -1,
  DESCENT: 1,
};

export const DEFAULT_SORT_MODEL = {
  column: null,
  sort: SORT_BY.ASCEND,
};

// list of sheets
export const DATA_PROP_ENUM = {
  Units: 'Units',
  FloorPlanTypes: 'FloorPlanTypes',
};

// UNITS

// list of column names
export const UNITS_PROP_NAME_ENUM = {
  PropertyName: 'Property Name',
  BuildingId: 'Building Id',
  SectionId: 'Section Id',
  FloorNumber: 'Floor Number',
  FloorPlanName: 'Floor plan name',
  ExternalId: 'External id',
  ExternalUnitNumber: 'External unit number',
  InternalUnitId: 'Internal Unit Id',
  DisplayName: 'Display name',
  UnitDescription: 'Unit description',
  Availability: 'Availability',
  AvailableAt: 'Available At',
  Price: 'Price',
  Square: 'Square',
  Kids: 'Kids',
  Animals: 'Animals',
  HouseRules: 'House Rules',
  Parking: 'Parking',
  UnitIdDb: 'Unit Id db',
};

// each column name should be placed to appropriate data type
export const UNITS_PROP_TYPES = {
  [CELL_TYPE_ENUM.NUMBER]: [
    UNITS_PROP_NAME_ENUM.FloorNumber,
    UNITS_PROP_NAME_ENUM.Square,
    UNITS_PROP_NAME_ENUM.Price,
    UNITS_PROP_NAME_ENUM.BuildingId,
    UNITS_PROP_NAME_ENUM.SectionId,
    UNITS_PROP_NAME_ENUM.ExternalUnitNumber,
  ],
  [CELL_TYPE_ENUM.TEXT]: [
    UNITS_PROP_NAME_ENUM.PropertyName,
    UNITS_PROP_NAME_ENUM.FloorPlanName,
    UNITS_PROP_NAME_ENUM.ExternalId,
    UNITS_PROP_NAME_ENUM.InternalUnitId,
    UNITS_PROP_NAME_ENUM.UnitDescription,
    UNITS_PROP_NAME_ENUM.UnitIdDb,
  ],
  [CELL_TYPE_ENUM.CHECKBOX]: [
    UNITS_PROP_NAME_ENUM.Availability,
    UNITS_PROP_NAME_ENUM.Kids,
    UNITS_PROP_NAME_ENUM.Animals,
    UNITS_PROP_NAME_ENUM.HouseRules,
    UNITS_PROP_NAME_ENUM.Parking,
  ],
  [CELL_TYPE_ENUM.DATE]: [UNITS_PROP_NAME_ENUM.AvailableAt],
};

// list of non-editable columns
export const UNITS_NON_EDITABLE_PROPS = [
  UNITS_PROP_NAME_ENUM.PropertyName,
  // UNITS_PROP_NAME_ENUM.BuildingId,
  UNITS_PROP_NAME_ENUM.FloorNumber,
  UNITS_PROP_NAME_ENUM.FloorPlanName,
  UNITS_PROP_NAME_ENUM.ExternalId,
  UNITS_PROP_NAME_ENUM.Availability,
  UNITS_PROP_NAME_ENUM.AvailableAt,
  UNITS_PROP_NAME_ENUM.Price,
  UNITS_PROP_NAME_ENUM.UnitIdDb,
];

// list of sortable columns
export const UNITS_SORTABLE_PROPS = [
  UNITS_PROP_NAME_ENUM.FloorNumber,
  UNITS_PROP_NAME_ENUM.FloorPlanName,
  UNITS_PROP_NAME_ENUM.DisplayName,
  UNITS_PROP_NAME_ENUM.InternalUnitId,
  UNITS_PROP_NAME_ENUM.ExternalId,
  UNITS_PROP_NAME_ENUM.ExternalUnitNumber,
  UNITS_PROP_NAME_ENUM.Square,
  UNITS_PROP_NAME_ENUM.Price,
  UNITS_PROP_NAME_ENUM.PropertyName,
  UNITS_PROP_NAME_ENUM.BuildingId,
];

// FLOOR_PLAN_TYPES

// list of column names
export const FLOOR_PLAN_PROP_NAME_ENUM = {
  FloorPlanId: 'Floor plan id',
  FloorPlanName: 'Floor plan name',
  BathroomCount: 'Bathroom count',
  BedroomCount: 'Bedroom count',
  SpinnerLinks: '360 Spinner Links',
  VirtualTourLink: 'Virtual tour link',
};

// each column name should be placed to appropriate data type
export const FLOOR_PLAN_PROP_TYPES = {
  [CELL_TYPE_ENUM.NUMBER]: [
    FLOOR_PLAN_PROP_NAME_ENUM.BathroomCount,
    FLOOR_PLAN_PROP_NAME_ENUM.BedroomCount,
  ],
  [CELL_TYPE_ENUM.TEXT]: [
    FLOOR_PLAN_PROP_NAME_ENUM.FloorPlanName,
    FLOOR_PLAN_PROP_NAME_ENUM.FloorPlanId,
    FLOOR_PLAN_PROP_NAME_ENUM.SpinnerLinks,
    FLOOR_PLAN_PROP_NAME_ENUM.VirtualTourLink,
  ],
  [CELL_TYPE_ENUM.CHECKBOX]: [],
  [CELL_TYPE_ENUM.DATE]: [],
};

// list of non-editable columns
export const FLOOR_PLAN_NON_EDITABLE_PROPS = [
  FLOOR_PLAN_PROP_NAME_ENUM.FloorPlanId,
  FLOOR_PLAN_PROP_NAME_ENUM.FloorPlanName,
  FLOOR_PLAN_PROP_NAME_ENUM.BathroomCount,
  FLOOR_PLAN_PROP_NAME_ENUM.BedroomCount,
  FLOOR_PLAN_PROP_NAME_ENUM.SpinnerLinks,
  FLOOR_PLAN_PROP_NAME_ENUM.VirtualTourLink,
];

// list of sortable columns
export const FLOOR_PLAN_SORTABLE_PROPS = [
  FLOOR_PLAN_PROP_NAME_ENUM.FloorPlanName,
  FLOOR_PLAN_PROP_NAME_ENUM.BathroomCount,
  FLOOR_PLAN_PROP_NAME_ENUM.BedroomCount,
];
