import React, { useCallback, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { uploadFloorPlan, deleteFloorPlan } from 'shared/utils/Explorer3dApi';
import { isNil } from 'ramda';
import { updateSigntmapSettigns, createToast } from 'redux/actions';
import { extname } from './utils';
import { FLOOR_STATUS } from './types';
import { Button } from 'react-bootstrap';

const acceptStyle = {
  backgroundColor: '#D9EAFF',
};

function FloorItem({ status, progress, isSelected, number, onClick }) {
  const ref = useRef();
  const dispatch = useDispatch();

  const explorer3d = useSelector(state => state.explorer3d.current);

  const floorPlans = useSelector(
    state => state.explorer3d.current.settings.floorPlans
  );
  const floorPlan = floorPlans[number];

  const [innerProgress, setInnerProgress] = useState(null);

  const onDrop = useCallback(
    async files => {
      const res = await uploadFloorPlan({
        explorer3dId: explorer3d.id,
        number,
        file: files[0],
        onProgress: p => {
          setInnerProgress(p);
        },
      });

      dispatch(updateSigntmapSettigns({ floorPlans: res.floorPlans }));
      setInnerProgress(null);
    },
    [dispatch, number, explorer3d.id]
  );

  const handleDelete = useCallback(async () => {
    const res = await deleteFloorPlan({
      explorer3dId: explorer3d.id,
      number,
    });

    dispatch(updateSigntmapSettigns({ floorPlans: res.floorPlans }));
    dispatch(createToast('SUCCESS', 'Floor map removed'));
  }, [dispatch, number, explorer3d.id]);

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDrop,
    noClick: true,
    noDrag: status === FLOOR_STATUS.DOWNLOADED,
  });

  const style = useMemo(
    () => ({
      opacity: status === FLOOR_STATUS.DOWNLOADED ? 1 : 0.6,
      ...(isDragAccept ? acceptStyle : {}),
    }),
    [isDragAccept, status]
  );

  const name = floorPlan
    ? `floor${number}${extname(floorPlan)}`
    : 'photo not attached';

  const actualProgress = progress || innerProgress;

  let width = 0;

  if (isSelected && status === FLOOR_STATUS.DOWNLOADED) {
    width = '100%';
  }

  width = !isNil(actualProgress)
    ? ref.current.getBoundingClientRect().width * actualProgress
    : width;

  return (
    <div className="floor-item" {...getRootProps({ style })}>
      <div ref={ref} onClick={onClick}>
        <div
          className="progress"
          style={{
            width,
          }}
        />
        {isSelected && <div className="active-marker" />}
        <div className="floor-item-container">
          <input {...getInputProps()} />
          <div className="text-container">
            <div className="index">{number}</div>{' '}
            <p className="main-text">{name}</p>
          </div>
          {floorPlan && (
            <div className="d-flex align-items-end justify-content-center h-100">
              <Button
                className={`delete-button d-flex align-items-center justify-content-center ${isSelected ? 'item-selected' : ''} h-100`}
                onClick={handleDelete}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/img/trash.svg`}
                  alt="trash"
                />
              </Button>
            </div>
          )}

          {status !== FLOOR_STATUS.DOWNLOADED && (
            <img
              src={`${process.env.PUBLIC_URL}/img/dot.svg`}
              className="icon"
              alt="dot icon"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default FloorItem;
