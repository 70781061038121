import React, { useState, useMemo } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Row, Col, Button, CardGroup } from 'react-bootstrap';

import AlertTooltipIcon from '../../../shared/components/AlertTooltipIcon';
import renderDropZoneField from '../../../shared/components/form/DropZone';

const MapFileInput = ({
  handleSubmit,
  errorText,
  uploading,
  images,
  floors,
}) => {
  const [isFileSelected, setIsFileSelected] = useState(false);
  const submitButtonDisabled = useMemo(
    () => uploading || !isFileSelected || errorText,
    [uploading, isFileSelected]
  );

  const alert = images ? '' : <AlertTooltipIcon tooltip="No image uploaded" />;

  const getFloorImageURL = floor => {
    if (images && images[floor]) {
      return `${process.env.REACT_APP_MEDIA_URL}/${images[floor]}`;
    }

    return '';
  };

  return (
    <Row className="pt-3 pb-3 mb-3 bg-light">
      <Col>
        <Row>
          <Col className="mb-2">
            <h5>
              Minimap <span className="text-muted">(.png)</span> {alert}
            </h5>
          </Col>
          <Col>
            <Button
              onClick={handleSubmit}
              size="sm"
              disabled={submitButtonDisabled}
              style={{ minWidth: '100px' }}
            >
              {uploading ? 'Uploading...' : 'Upload'}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <CardGroup>
              {floors.map(floor => (
                <Field
                  name={`${floor}`}
                  label={
                    floor === 1
                      ? `${floor}-st floor`
                      : floor === 2
                        ? `${floor}-nd floor`
                        : floor === 3
                          ? `${floor}-d floor`
                          : floor > 3
                            ? `${floor}-th floor`
                            : ''
                  }
                  onChange={() => setIsFileSelected(true)}
                  component={renderDropZoneField}
                  key={floor}
                  image={getFloorImageURL(floor)}
                  base64
                  accept=".png"
                  style={{ marginRight: '10px' }}
                  maxSize={10}
                />
              ))}
            </CardGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

MapFileInput.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  uploading: PropTypes.bool,
};

MapFileInput.defaultProps = {
  uploading: false,
};

export default reduxForm({
  form: 'upload_map',
})(MapFileInput);
