import React, { useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loadExplorer3ds, showPopup, hidePopup } from 'redux/actions';
import PlusItemCard from 'containers/Companies/components/PlusItemCard';
import { createExplorer3d } from 'shared/utils/Explorer3dApi';
import Explorer3dItem from './Explorer3dItem';

function Explorer3dList() {
  const dispatch = useDispatch();
  const explorer3ds = useSelector(state => state.explorer3d?.list);

  function addNewExplorer3dHandler() {
    dispatch(
      showPopup({
        onSubmit: async data => {
          await createExplorer3d(data);
          dispatch(hidePopup());
          dispatch(loadExplorer3ds());
        },
        label: {
          text: 'Create explorer3d',
          color: 'black',
        },
        submitButton: {
          text: 'Create',
          variant: 'success',
        },
        formData: [
          {
            type: 'input',
            name: 'name',
            props: {
              placeholder: 'Explorer3d name',
              type: 'text',
              // onChangeValidate: true,
            },
          },
        ],
      })
    );
  }

  useEffect(() => {
    dispatch(loadExplorer3ds());
  }, [dispatch]);

  return (
    <Container className="explorer3ds mt-4">
      <Row>
        {explorer3ds.map(s => (
          <Explorer3dItem key={s.id} data={s} />
        ))}
        <PlusItemCard onClick={addNewExplorer3dHandler}></PlusItemCard>
      </Row>
    </Container>
  );
}

export default Explorer3dList;
