import React, { createRef, useEffect, useState } from 'react';
import { Button, FormControl, Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';

function ContentFileInput({ onChange, isMultiple, acceptFormats, onSubmit }) {
  const input = createRef();
  const [isLoading, setIsLoading] = useState(false);

  const awsUrls = useSelector(state => state.explorer3d?.current?.awsUrls);

  const changeHandler = e => {
    setIsLoading(true);

    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];

      onChange && onChange(file);
    }
    const files = input.current.files;

    onSubmit(files);
  };

  useEffect(() => {
    if (awsUrls?.length) {
      setIsLoading(false);
    }
  }, [awsUrls]);

  return (
    <Container
      className={
        isLoading
          ? 'drop-files-container drop-files-container-opacity'
          : 'drop-files-container'
      }
    >
      {!!isLoading && (
        <div className="spinner-border text-primary loader" role="status">
          <span className="visually-hidden"></span>
        </div>
      )}
      <h1>Drop files here for upload</h1>
      <Button>Browse files</Button>
      <FormControl
        type="file"
        accept={acceptFormats}
        onChange={changeHandler}
        ref={input}
        multiple={isMultiple}
        className="form-control-file"
      />
    </Container>
  );
}

export default ContentFileInput;
