export const LOAD_COMPANIES = 'LOAD_COMPANIES';
export const LOAD_LOCATIONS_OF_COMPANY = 'LOAD_LOCATIONS_OF_COMPANY';
export const LOAD_LOCATION = 'LOAD_LOCATION';
export const LOAD_FURNITURE = 'LOAD_FURNITURE';
export const LOAD_HOTSPOTS = 'LOAD_HOTSPOTS';
export const LOAD_LOCATION_HOTSPOTS = 'LOAD_LOCATION_HOTSPOTS';
export const LOAD_TILE_STATS = 'LOAD_TILE_STATS';
export const RESET_LOCATIONS = 'RESET_LOCATIONS';
export const RESET_LOCATION = 'RESET_LOCATION';
export const RESET_COMPANY_LOCATIONS = 'RESET_COMPANY_LOCATIONS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const CREATE_TOAST = 'CREATE_TOAST';
export const DELETE_TOAST = 'DELETE_TOAST';
export const SET_SEARCH_NAME = 'SET_SEARCH_NAME';
export const SET_HIDE_EMPTY_FOLDERS = 'SET_HIDE_EMPTY_FOLDERS';
export const SHOW_POPUP = 'SHOW_POPUP';
export const HIDE_POPUP = 'HIDE_POPUP';
export const SHOW_CONTEXT_MENU = 'SHOW_CONTEXT_MENU';
export const HIDE_CONTEXT_MENU = 'HIDE_CONTEXT_MENU';
export const LOAD_EXPLORER3DS = 'LOAD_EXPLORER3DS';
export const SET_CURRENT_EXPLORER3D = 'SET_CURRENT_EXPLORER3D';
export const UPDATE_FLOOR_PLANS = 'UPDATE_FLOOR_PLANS';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const UPDATE_BUILDING_INFO = 'UPDATE_BUILDING_INFO';
export const UPDATE_EXPLORER3D_SETTINGS = 'UPDATE_EXPLORER3D_SETTINGS';
export const SET_AMENITIES = 'SET_AMENITIES';
export const SET_PROPERTY_DETAILS = 'SET_PROPERTY_DETAILS';
export const LOAD_UNITS_LIST = 'LOAD_UNITS_LIST';
export const LOAD_UNITTYPES_LIST = 'LOAD_UNITTYPES_LIST';
export const LOAD_AWS_URLS = 'LOAD_AWS_URLS';
export const DELETE_AWS_FILE = 'DELETE_AWS_FILE';
export const UPLOAD_AWS_FILES = 'UPLOAD_AWS_FILES';
export const UPLOAD_AWS_FILES_ERROR = 'UPLOAD_AWS_FILES_ERROR';
export const SET_DATA_CSV = 'LOAD_DATA_CSV';
export const SET_IS_DATA_CSV_LOADING = 'SET_IS_DATA_CSV_LOADING';
export const SET_PROPERTY_SVG_COORDINATES = 'SET_PROPERTY_SVG_COORDINATES';
export const SET_FLOOR_SVGS = 'SET_FLOOR_SVGS';
