import { TAB_ENUM } from 'containers/Explorer3d/types';
import * as actionTypes from '../actions/types';
import { produce } from 'immer';

const initialState = {
  list: [],
  current: null,
  activeTab: TAB_ENUM.SETTINGS,
};

export default function Explorer3dReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_EXPLORER3DS: {
      return {
        ...state,
        list: action.payload,
      };
    }
    case actionTypes.DELETE_AWS_FILE: {
      return {
        ...state,
        list: action.payload,
      };
    }
    case actionTypes.SET_CURRENT_EXPLORER3D: {
      return {
        ...state,
        activeTab: TAB_ENUM.SETTINGS,
        current: action.payload,
      };
    }
    case actionTypes.SET_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload.tab,
      };
    }
    case actionTypes.SET_PROPERTY_DETAILS: {
      const details = action.payload;

      const nextState = produce(state, s => {
        s.current.propertyDetails = details;
      });

      return nextState;
    }
    case actionTypes.UPDATE_FLOOR_PLANS: {
      const floorPlans = action.payload;

      const nextState = produce(state, s => {
        s.current.settings.floorPlans = floorPlans;
      });

      return nextState;
    }
    case actionTypes.SET_AMENITIES: {
      const nextState = produce(state, s => {
        s.current.amenities = action.payload;
      });

      return nextState;
    }
    case actionTypes.UPDATE_EXPLORER3D_SETTINGS: {
      const nextState = produce(state, s => {
        s.current.settings = { ...s.current.settings, ...action.payload };
      });

      return nextState;
    }
    case actionTypes.UPDATE_BUILDING_INFO: {
      const { id, info } = action.payload;

      const nextState = produce(state, s => {
        for (const b of s.current.buildings) {
          if (b.id === id) {
            b.info = { ...b.info, ...info };
          }
        }
      });

      return nextState;
    }
    case actionTypes.LOAD_UNITTYPES_LIST: {
      const nextState = produce(state, s => {
        s.current.unittypes = action.payload;
      });

      return nextState;
    }

    case actionTypes.LOAD_AWS_URLS: {
      const nextState = produce(state, s => {
        s.current.awsUrls = action?.payload;
      });

      return nextState;
    }

    case actionTypes.LOAD_UNITS_LIST: {
      const nextState = produce(state, s => {
        s.current.units = action.payload;
      });

      return nextState;
    }
    default:
      return state;
  }
}
