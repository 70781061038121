import React, { memo, useEffect, useRef } from 'react';
import { Popover } from 'react-bootstrap';

const SIZE_MULTIPLY = 1.2;
const POSITION_CORRECTOR = 75;

export const SVGRenderer = memo(function SVGRenderer({
  svgContent,
  coords,
  cellValue,
  floorNumber,
}) {
  const svgContainerRef = useRef(null);

  useEffect(() => {
    const svgContainer = svgContainerRef.current;
    const cellHighlighted = svgContent?.querySelector(
      `[external-id="${cellValue}"]`
    );
    const prevCellHighlighted = svgContent?.querySelector(
      '.with-popup-property'
    );

    if (prevCellHighlighted) {
      prevCellHighlighted.classList.remove('with-popup-property');
    }

    if (cellHighlighted) {
      cellHighlighted.classList.add('with-popup-property');
    }

    if (svgContent && svgContainer) {
      const viewBox = svgContent.getAttribute('viewBox')?.split(' ');
      const widthSVG = viewBox?.[2] * SIZE_MULTIPLY;
      const heightSVG = viewBox?.[3] * SIZE_MULTIPLY;
      const top = -((coords?.y ?? 0) * SIZE_MULTIPLY - POSITION_CORRECTOR);
      const left = -((coords?.x ?? 0) * SIZE_MULTIPLY - POSITION_CORRECTOR);

      const style = `
        position: absolute;
        top: ${top}px;
        left: ${left}px;
        width: ${widthSVG}px;
        height: ${heightSVG}px;
      `;

      svgContent?.classList.add('with-popup-image-svg');
      svgContainer.innerHTML = '';
      svgContainer.appendChild(svgContent);
      svgContainer.setAttribute('style', style);
    }

    return () => {
      if (cellHighlighted) {
        cellHighlighted.classList.remove('with-popup-property');
      }

      if (svgContainer) {
        svgContainer.innerHTML = '';
        svgContainer.remove(svgContent);
        svgContainer.setAttribute('style', '');
      }
    };
  }, [cellValue, coords?.x, coords?.y, svgContent]);

  return (
    <Popover className="with-popup-popover" placement="left">
      <Popover.Content>
        <div className="d-flex flex-column align-items-center justify-content-center text-center">
          <p className="with-popup-title">Floor Number: {floorNumber}</p>
          <p className="with-popup-title">External ID: {cellValue}</p>
          {coords && svgContent ? (
            <div className="with-popup-image">
              <div ref={svgContainerRef} />
            </div>
          ) : (
            <div className="with-popup-subtitle">No image</div>
          )}
        </div>
      </Popover.Content>
    </Popover>
  );
});
