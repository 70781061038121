import React, { useEffect, useState } from 'react';
import { Form, Row, Col, InputGroup } from 'react-bootstrap';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';

const SettingsLinks = ({ locationId, setSettingsLink, settingsLink }) => {
  const [click, setClick] = useState(false);

  const handleChangeCheck = e => {
    e.persist();
    setSettingsLink(prev => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  const handleChangeString = e => {
    e.persist();
    setSettingsLink(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (!click) return;

    const timer = setTimeout(() => setClick(false), 1500);

    return () => clearTimeout(timer);
  }, [click]);

  const createLink = () =>
    Object.keys(settingsLink).reduce((acc, cur) => {
      return acc + `&${cur}=${encodeURIComponent(settingsLink[cur])}`;
    }, `${process.env.REACT_APP_PLAYER_URL}/?locationId=${locationId}`);

  const copyLink = () => {
    navigator.clipboard.writeText(createLink());
    setClick(true);
  };

  return (
    <>
      <Row className="mb-3">
        <Col>
          <div
            className="collapse show"
            style={{ backgroundColor: 'rgb(244, 244, 244)' }}
          >
            <div>
              <Form.Check
                className="mb-2"
                name="autoRotate"
                checked={settingsLink.autoRotate}
                type="checkbox"
                label="Auto rotate"
                onChange={handleChangeCheck}
              />
              <Form.Check
                className="mb-2"
                name="autoFollow"
                checked={settingsLink.autoFollow}
                type="checkbox"
                label="Auto follow"
                onChange={handleChangeCheck}
              />
              <Form.Check
                className="mb-2"
                name="autoPlay"
                checked={settingsLink.autoPlay}
                type="checkbox"
                label="Auto play"
                onChange={handleChangeCheck}
              />
              <Form.Check
                className="mb-2"
                name="fullscreen"
                checked={settingsLink.fullscreen}
                type="checkbox"
                label="Fullscreen"
                onChange={handleChangeCheck}
              />
              <Form.Control
                className="mb-2"
                name="locationName"
                value={settingsLink.locationName}
                type="text"
                label="Start location name"
                onChange={handleChangeString}
                style={{
                  width: 250,
                }}
              />
              <Form.Label htmlFor="exampleColorInput">Panel color</Form.Label>
              <Form.Control
                className="mb-2"
                name="panelColor"
                style={{
                  width: 50,
                }}
                value={settingsLink.panelColor}
                type="color"
                onChange={handleChangeString}
              />
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label column sm={3}>
                  Link
                </Form.Label>
                <InputGroup>
                  <Form.Control type="text" readOnly value={createLink()} />
                  <InputGroup.Text
                    style={{
                      cursor: 'pointer',
                      backgroundColor: click ? '#28a745' : '#e9ecef',
                    }}
                    onClick={copyLink}
                  >
                    <ContentCopyIcon />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </div>
          </div>
          <div className="bg-secondary border border-secondary" />
        </Col>
      </Row>
    </>
  );
};

export default SettingsLinks;
